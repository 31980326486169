import { useEffect, useRef } from 'react';

/**
 * Given a value, this hook will return the previous version
 * of that value, and remain updated whenever a component is
 * updated. This is useful when replicating functionality from
 * componentWillReceiveProps, when the previous and next props
 * values need to be compared.
 */
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export default usePrevious;
