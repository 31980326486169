import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { createTranslator, pluralize } from 'helpers/i18n';
import Session, {
  isProviderAdmin,
  getProviderAccessDetails,
  getUnclaimedLocations,
  getOrganizationOptions,
} from 'models/Session';
import {
  Panel,
  Styles,
  ButtonBar,
  Help,
  Button,
  Link,
  Icon,
  Colors,
} from 'components/utils';

const { Secondary } = Styles;
const {
  WHITE,
  NOTIFICATION_FILL,
  TIP_DEFAULT,
  MEDIUM_LIGHT_GRAY_FILL,
} = Colors;

const tr = createTranslator({
  non_verified_admin: {
    title: 'Request Admin Access',
    button: 'Submit Request',
    bullets: ['Manage your team', 'Edit your organizations settings'],
    tooltip:
      'Request your verified administrator to make you an administrator who can manage the functions.',
    note: '',
  },
  verified_admin: {
    title: count => `Claim ${pluralize(count, 'Location')}`,
    button: 'Claim Location',
    bullets: ['Manage your team', 'Edit your organizations settings'],
    tooltip:
      'By claiming a location, you will become a verified admin for that location. You, as a verified administrator, will be able to grant access and change provider settings.',
    note: 'Note: Now anyone in your organization can make those changes.',
  },
});

const Container = styled.div`
  position: relative;
  background-color: ${TIP_DEFAULT};
  padding: 11px 14px;
  border: solid 1px ${MEDIUM_LIGHT_GRAY_FILL};
  border-radius: 5px;
  margin: 6px 0 8px;
`;

const Title = styled.h4`
  font-weight: 600;
`;

const Bullet = styled.ul`
  margin: 0 0 1rem 1rem;
  font-style: italic;
`;

const Text = styled.span`
  display: inline-block;
  text-decoration: none;
`;

const buttonStyle = css`
  border-radius: 22px;
  width:100%;
  display: flex;
  justify-content: center;
`;

const linkStyle = css`
  width: 100%;
`;

export const getUserType = (session) => {
  const {
    isAdmin,
    hasOpenProviders: openProvider,
    hasClosedProviders: closedProvider,
    isVerifiedAdmin: hasVerifiedLocation,
  } = getProviderAccessDetails(session.user, session.purchases);

  if (isAdmin && hasVerifiedLocation) {
    return 'verified_admin';
  }
  if (!isAdmin && hasVerifiedLocation) {
    return 'non_verified_admin';
  }
  if (openProvider && closedProvider) {
    return 'verified_admin';
  }
  if (!openProvider && !isAdmin) {
    return 'non_verified_admin';
  }
  if (!hasVerifiedLocation) {
    return 'verified_admin';
  }
  return undefined;
};

export const shouldShowContent = (session) => {
  const orgOptionsLength = isProviderAdmin(session.user) ? getOrganizationOptions(session, 'verified_admin')?.length : getOrganizationOptions(session)?.length;
  return orgOptionsLength > 0;
};

/**
 * Component for managing the verified admin workflow
 */
const VerifiedAdminActions = ({
  session,
  onClick,
  url,
  target,
  isMobile,
}) => {
  const userType = getUserType(session);

  const locationCount = getUnclaimedLocations(session)?.length;

  const renderBulletinPoints = () => {
    const bulletinPoints = tr(`${userType}.bullets`);
    if (bulletinPoints?.length) {
      return (
        <Bullet>
          {bulletinPoints.map((text) => (
            <li key={text}>{text}</li>
          ))}
        </Bullet>
      );
    }
    return undefined;
  };

  return (
    <>
      { shouldShowContent(session) ? (
        <Container>
          <Panel spacing={8} orientation="vertical">
            <ButtonBar spacing={8}>
              <Title>{tr(`${userType}.title`, locationCount)}</Title>
              <Help position={isMobile ? 'left' : 'right'} triggerOn="hover">
                <Panel spacing={4}>
                  {tr(`${userType}.tooltip`)}
                </Panel>
                <Panel spacing={4}>
                  {tr(`${userType}.note`)}
                </Panel>
              </Help>
            </ButtonBar>
            <Secondary>{renderBulletinPoints()}</Secondary>
            <Link className={linkStyle} to={url} target={target}>
              <Button
                className={buttonStyle}
                onClick={onClick}
                size="sm"
                color={NOTIFICATION_FILL}
                leftIcon={
              userType !== 'non_verified_admin' ? (
                <Icon name="mp-padlock" color={WHITE} />
              ) : undefined
            }
              >
                <Text>{tr(`${userType}.button`)}</Text>
              </Button>
            </Link>
          </Panel>
        </Container>
      ) : undefined }
    </>
  );
};

VerifiedAdminActions.propTypes = {
  /** Session data */
  session: Session.isRequired,
  /** Callback when button is clicked */
  onClick: PropTypes.func,
  /** Link for become an admin popup */
  url: PropTypes.string.isRequired,
  /** Link target property */
  target: PropTypes.string,
  /** Mobile device */
  isMobile: PropTypes.bool,
};

VerifiedAdminActions.defaultProps = {
  onClick: undefined,
  target: '',
  isMobile: false,
};

export default VerifiedAdminActions;
