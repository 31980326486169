import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';

const ControlBtn = styled.button`
  padding: 8px 15px;
  border-radius: 4px;
  border: solid 1px #dfdfe2;
  background-color: #fff;
  font-size: 10px;
  cursor: pointer;
  &:hover {
    border:  ${props => (!props.disabled && 'solid 1px #7d7e8b')};
  }
`;

const ControlButton = ({ children, ...props }) => (
  <ControlBtn {...props}>{children}</ControlBtn>
);
ControlButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]).isRequired,
};

export default ControlButton;
