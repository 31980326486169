/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as referralActions from 'ducks/referrals';
import { trackEvent } from 'helpers/analytics';

import styled from 'react-emotion';
import { css } from 'emotion';
import { createTranslator } from 'helpers/i18n';
import { searchPatientsAndReferrals, loadContent } from 'helpers/search';
import { debounce } from 'lodash';

import {
  Divider, TextField, Popup, Icon, LoadingPanel, Styles,
} from 'components/utils';
import ThemeControl from 'components/utils/ThemeControl';

import PatientPreviewCard from 'components/home/PatientPreviewCard';

const { PopupContent, Secondary } = Styles;
const { colors } = ThemeControl.v2;

const {
  GRAY_FILL,
  BLACK,
  BORDER_COLOR,
  TRANSPARENT,
  WHITE,
} = colors;

const tr = createTranslator({
  empty: 'No results found.',
  error: 'Error searching. Please try again.',
  placeholder: 'Search by Name or ID',
  deidentified: {
    name: 'Deidentified Patient',
    patient_number: 'Deidentified',
  },
});

const inputStyle = css`
  input {
    font-size: 12px;
    line-height: 1.36;
    box-shadow: none;
    border: 0px;
    margin: 0px;
    padding: 0px;
    color: ${BLACK};
    background-color: ${TRANSPARENT};
    outline: none;
    &, &:focus {
      border: 1px solid ${TRANSPARENT};
    }
    ::placeholder {
      color: ${GRAY_FILL};
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: ${BLACK};
    }
    ::-ms-input-placeholder {
      color: ${BLACK};
    }
  }
`;

const Container = styled.div`
  position: relative;
  margin-top: 4px;
  width: 240px;
  height: 34px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
  border: solid 1px ${BORDER_COLOR};
  background-color: ${WHITE};
  &:hover {
    border:  ${props => (!props.focused && `solid 1px ${GRAY_FILL}`)};
  }
  outline: none;
  .popup-content {display: ${props => (props.hideContent ? 'none' : 'inline-block')};}
  .popup-overlay {display: ${props => (props.hideContent ? 'none !important' : 'inherit')};}
`;

const SearchResultsContainer = styled.div`
  ${props => !props.loading && 'height: 100%;'}
  ${props => props.loading && `
    display: flex;
    align-items: center;
    height: 300px;
    width: 300px;
  `}
`;

const boxedIcon = css`
  padding: 0px 4px;
  color: ${BLACK};
`;

const DESIRED_INPUT_LENGTH = 4;

/**
 * Search text input box for the MenuBar
 */
class SearchBox extends React.Component {
  static propTypes = {
    history: ReactRouterPropTypes.history.isRequired,
    onClose: PropTypes.func,
    createShapedReferral: PropTypes.func.isRequired,
    referralFilter: PropTypes.func,
  };

  static defaultProps = {
    onClose: undefined,
    referralFilter: undefined,
  };

  constructor(props) {
    super(props);
    this.inputListener = debounce(this.handleSearchRequest, 1000);
    this.state = {
      focused: false,
      loading: false,
      contentLoading: false,
      input: '',
      results: null,
      error: '',
      requestNumber: 0,
      limit: DESIRED_INPUT_LENGTH,
    };
  }

  isInt = (value) => {
    if (Number.isNaN(value)) {
      return false;
    }
    const x = parseFloat(value);
    return (x | 0) === x; // eslint-disable-line no-bitwise
  }

  handleInputChange = (input) => {
    /* Reset the results here to get the spinner going */
    this.setState({ input, results: null }, this.inputListener);
  }

  handleSearchRequest = () => {
    const { input, limit } = this.state;
    if (input.length < limit) {
      return;
    }

    this.setState(({ requestNumber }) => ({
      requestNumber: requestNumber + 1,
      loading: true,
      contentLoading: true,
    }), () => {
      const { requestNumber } = this.state;
      const requestIsCurrent = () => {
        const { focused, requestNumber: currentRequestNumber } = this.state;
        return focused && requestNumber === currentRequestNumber;
      };
      searchPatientsAndReferrals(input, requestIsCurrent, tr('deidentified'))
        .then((results) => {
          this.setState({
            loading: false,
            error: '',
            results,
          }, () => {
            this.focus();
            if (results && results.length > 0) {
              loadContent(results, requestIsCurrent)
                .then((fullResults) => {
                  this.setState({ results: fullResults, contentLoading: false }, this.focus);
                })
                .catch((error) => {
                  this.setState({ loading: false, error }, this.focus);
                });
            }
          });
        })
        .catch((error) => {
          this.setState({ loading: false, error }, this.focus);
        });
    });
  }

  handleReset = () => {
    this.setState({
      focused: false,
      loading: false,
      input: '',
      error: '',
      results: null,
      limit: DESIRED_INPUT_LENGTH,
      contentLoading: false,
    }, () => {
      this.blur();
      if (this.props.onClose) { this.props.onClose(); }
    });
  }

  handleSubmit = () => {
    const { input, results } = this.state;
    if (results && this.isInt(input)) {
      const id = parseInt(input, 10);
      if (results.some(({ referrals }) => (referrals || []).some(r => r.id === id))) {
        this.handleReset();
        this.props.history.push(`/referrals/${id}`);
      }
    } else if (!this.state.loading) {
      // Only react to submit in this way if we are not loading already
      this.setState({
        limit: Math.min(input.length, DESIRED_INPUT_LENGTH),
      }, () => { this.handleSearchRequest(); });
    }
  }

  blur = () => {
    if (this.input) { this.input.field.blur(); }
  }

  focus = () => {
    if (this.input) {
      this.input.field.focus();
    }
  }

  toggleFocus = (focused) => {
    this.setState({ focused });
  }

  referralMatchesQuery = (referral) => {
    const { id } = referral;
    const { input } = this.state;
    const num = Number(input);
    // eslint-disable-next-line
    if (!isNaN(num)) {
      const idStr = String(id);
      const inputStr = String(input);
      if (idStr.indexOf(inputStr) === 0) {
        return {
          ...referral,
          queryInputMatch: true,
        };
      }
    }
    return referral;
  };

  sortReferralsByCancelled = ({ reservationStatus: rs1 }, { reservationStatus: rs2 }) => {
    if (rs1 !== 'cancelled' && rs2 === 'cancelled') {
      return -1;
    }
    if (rs1 === 'cancelled' && rs2 !== 'cancelled') {
      return 1;
    }
    return 0;
  };

  renderResults = (results) => results.map(({ referrals = [], isFiltered, ...patient }, idx) => (
    <>
      {idx > 0 && <Divider key={`${patient.id}-divider`} margin={5} />}
      <PatientPreviewCard
        key={patient.id}
        patient={patient}
        referrals={(referrals || [])
          .filter(r => !!r)
          .map(this.props.createShapedReferral)
          .map(this.referralMatchesQuery)}
        orderReferralsByUpdateAt
        date="discharge_date"
        history={this.props.history}
        loading={this.state.contentLoading}
        onSelect={this.handleReset}
        isFiltered={isFiltered}
        showUpdatedAtColumn
      />
    </>
  ))

  filterResults = results => (this.props.referralFilter
    ? results.map((result) => {
      const filteredReferrals = (result.referrals || []).filter((referral) => {
        if (this.referralMatchesQuery(referral).queryInputMatch) {
          // always show exact matches, never filter them
          return true;
        }
        // run the custom referralFilter for any others
        return this.props.referralFilter(referral);
      });
      return {
        ...result,
        referrals: filteredReferrals,
        isFiltered: filteredReferrals.length < (result.referrals || []).length,
      };
    })
    : results
  );

  renderErrorOrEmpty = (error) => (
    <PopupContent><Secondary>{error ? tr('error') : tr('empty')}</Secondary></PopupContent>
  );

  render() {
    const {
      input, loading, results, error, focused, limit,
    } = this.state;
    const filteredResults = this.filterResults(results || [], input);

    return (
      <>
        <Container focused={focused} hideContent={input.length < limit}>
          <Popup
            disableScrolling
            open={focused || (results && results.length > 0)}
            onClose={this.handleReset}
            width={350}
            trigger={<Icon className={boxedIcon} name="mp-search" />}
            triggerElement={null}
            triggerOn="none"
            position="bottom left"
            offsetY={24}
          >
            {input.length > limit ? (
              <SearchResultsContainer data-testid="search-result-container" loading={loading || !results}>
                {(loading || !results)
                  ? <LoadingPanel full color={GRAY_FILL} />
                  : (results && results.length > 0)
                    ? this.renderResults(filteredResults)
                    : this.renderErrorOrEmpty(error)}
              </SearchResultsContainer>
            ) : this.renderErrorOrEmpty(error)}
          </Popup>
          <TextField
            ref={(ref) => { this.input = ref; }}
            placeholder={tr('placeholder')}
            className={inputStyle}
            width={180}
            onChange={this.handleInputChange}
            value={input}
            onFocus={() => {
              trackEvent('Header Search', 'Click', 'Open');
              this.toggleFocus(true);
            }}
            onBlur={() => { this.toggleFocus(false); }}
            onSubmit={this.handleSubmit}
          />
        </Container>
      </>
    );
  }
}

export default compose(
  withRouter,
  connect(null, referralActions),
)(SearchBox);
