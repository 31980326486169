import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import Link from 'components/utils/Link';
import Message from 'models/Message';
import Session from 'models/Session';
import { createTranslator } from 'helpers/i18n';
import { errorToast } from 'helpers/ui';

import {
  Panel, Placeholder, Divider, Styles,
} from 'components/utils';

import MessageListItem from './MessageListItem';

const { LinkText } = Styles;

const CONTENT_AREA_HEIGHT = 280;

const tr = createTranslator({
  all: 'View All Messages',
  unread: 'View Unread Messages',
  send: 'Send New',
  error: 'Could not load this conversation; perhaps you do not have permission to view. Please contact Aidin support if you need assistance.',
  empty: 'You have no messages yet.',
});

const SplitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: -20px;
  width: 330px;
`;

const PopupContent = styled.div`
  padding: 10px;
  position: relative;
`;

const PopupPanel = styled(Panel)`
  overflow-x: hidden;
  max-height: ${CONTENT_AREA_HEIGHT}px;
`;

/**
 * Display list of messages, for display within popup
 */
export default class MessageList extends React.Component {
  static propTypes = {
    /** Close handler for the wrapping popup */
    close: PropTypes.func.isRequired,
    /** Handle opening a message */
    onMessageClick: PropTypes.func,
    /** Handle when send is clicked */
    onSendClick: PropTypes.func.isRequired,
    /** The list of messages to display */
    messages: PropTypes.arrayOf(Message).isRequired,
    /** Session info */
    session: Session.isRequired,
  }

  static defaultProps = {
    onMessageClick: undefined,
  };

  handleOpenMessage = (message) => {
    if (this.props.onMessageClick) {
      this.props.onMessageClick(message).catch(() => {
        errorToast(tr('error'));
      });
    }
  }

  handleSendNewMessage = () => {
    this.props.close();
    this.props.onSendClick();
  }

  render() {
    const empty = this.props.messages.length === 0;
    return (
      <PopupContent>
        <PopupPanel spacing={14}>
          <div>
            {empty && (
              <Placeholder height={CONTENT_AREA_HEIGHT}>
                {tr('empty')}
              </Placeholder>
            )}
            {this.props.messages.map(message => (
              <div key={message.id} data-testid="listed_messages">
                <MessageListItem
                  organizations={this.props.session.organizations}
                  message={message}
                  onClick={() => {
                    this.props.close();
                    this.handleOpenMessage(message);
                  }}
                />
                <Divider margin={0} />
              </div>
            ))}
          </div>
          <SplitContainer>
            <Link to="/messages?filter=all"><LinkText onClick={this.props.close}>{tr('all')}</LinkText></Link>
            {!empty && <Link to="/messages?filter=unread"><LinkText onClick={this.props.close}>{tr('unread')}</LinkText></Link>}
          </SplitContainer>
        </PopupPanel>
      </PopupContent>
    );
  }
}
