/* eslint-disable camelcase, no-nested-ternary */

import { createTranslator, numeric, relativeTimeSince } from 'helpers/i18n';
import { DateTime } from 'helpers/dates';
import {
  PROVIDER_GROUP_HOME_SERVICE,
  PROVIDER_GROUP_INPATIENT,
  PROVIDER_GROUP_TRANSACTIONAL,
  PROVIDER_GROUP_OUTPATIENT,
  getReferralProviderTypeModels,
} from 'models/ProviderType';
import { isReferralDoc, getTextForChecklist } from 'helpers/referrals';

const labelByReferralType = oracle => ({
  referral,
  referral: {
    provider_type: type,
  } = {},
}) => numeric(type, typeof oracle === 'function' ? oracle(type, referral) : oracle);

export const referralLabels = {
  authorization_code: 'Auth #',
  confirmed_patient_name: 'Confirmed Patient Name',
  start_of_care: labelByReferralType({
    auth: 'Date auth begins',
    other: 'Start of care',
  }),
  end_of_care: labelByReferralType({
    auth: 'Date auth ends',
    other: 'End of care',
  }),
  drop_off_provider: 'Auth needed for',
  drop_off_provider_id: 'Auth needed for',
  care_types: 'Clinical Needs requested',
  care_types_covered: 'Clinical Needs covered',
  care_types_covered_attributes: 'Clinical Needs covered',
  npi: 'NPI',
  tax_id: 'Tax ID',
  pick_up_at: 'Pick up at',
  appointment_type: 'Appointment Type',
  follow_up_phone_number: 'Peer to Peer Number',
  standard_appeals_number: 'Standard Appeals Number',
  fast_track_appeals_number: 'Fast Track Appeals Number',
  coverage_category: 'Category for Coverage',
  authorization_note: 'Authorization Notes',
};

const declineLabels = {
  declined_reason: 'Reason',
};

const authAuthorizedLabels = {
  coverage_category: 'Covered Category',
};

const authDeclineLabels = {
  authorization_code: 'Auth/Ref #',
  coverage_category: 'Day Type Denied',
  declined_reason: 'Denial Reason',
  denial_status: 'Denial Status',
  notified_date: 'Notified Date',
  appealed_date: 'Appealed Date',
  end_of_care: 'Denied End Date',
  start_of_care: 'Denied Begin Date',
};

const authUnderReviewLabels = {
  authorization_code: 'Reference #',
};

export const createReferralLabelTranslator = (
  referral,
  sentReferral,
) => createTranslator(
  getTextForChecklist(referral, {
    auth: numeric(sentReferral.status, {
      unavailable: {
        ...referralLabels,
        ...authDeclineLabels,
      },
      available: {
        ...referralLabels,
        ...authAuthorizedLabels,
      },
      under_review: {
        ...referralLabels,
        ...authUnderReviewLabels,
      },
      other: referralLabels,
    }),
    other: {
      ...referralLabels,
      ...declineLabels,
    },
  }),
);

export const coverageCategories = {
  inpatient: 'Inpatient',
  observation: 'Observation',
  outpatient: 'Outpatient',
  behavioral_health: 'Behavioral Health',
  burn_unit: 'Burn Unit',
  ccu: 'CCU',
  detox: 'Detox',
  home_health: 'Home Health',
  hospice: 'Hospice',
  icu: 'ICU',
  oncology: 'Oncology',
  pediatrics: 'Pediatrics',
  physical_rehabilitation: 'Physical Rehabilitation',
  picu: 'PICU',
  telemetry: 'Telemetry',
  med_surg: 'Med/Surg',
  outpatient_in_a_bed: 'Outpatient in a Bed',
  skilled_nursing_level_1: 'Skilled Nursing Level 1',
  skilled_nursing_level_2: 'Skilled Nursing Level 2',
  skilled_nursing_level_3: 'Skilled Nursing Level 3',
  sub_acute: 'Sub-Acute',
  long_term_acute_care: 'Long Term Acute Care',
  surgery: 'Surgery',
  medicine: 'Medicine',
  ob_gyn: 'OB/GYN',
  ob_ante_partum: 'OB Ante Partum',
  nicu: 'NICU',
  inpatient_rehabilitation: 'Inpatient Rehab',
  denial: 'Denial',
};

/** Shortened version of hours and minutes helper */
export const hoursAndMinutesShort = (time, now = DateTime.now()) => {
  if (!time) { return 'Unknown'; }
  const diff = time.diff(now, 'minutes');
  const hours = parseInt(diff / 60, 10);
  const mins = diff % 60;
  const hoursStr = hours ? `${hours}h ` : '';
  return `${hoursStr}${(mins > 0 ? ` ${mins}m` : '')}`;
};

const getTimeForTask = (referral, type) => {
  const { tasks = [] } = referral;
  const task = tasks.find(({ task_type: t }) => t === type);
  return task?.completed_at;
};

/** Step language shown on main and referral dashboard */
export const dashboardReservationStatus = {
  sending: {
    drafted: (_, { verbose = false } = {}) => numeric(verbose, {
      true: 'Drafted, Not Sent',
      other: 'Drafted',
    }),
    sending: (_, { verbose = false } = {}) => numeric(verbose, {
      true: 'Drafted, Not Sent',
      other: 'Created',
    }),
    sent: referral => getTextForChecklist(referral, {
      auth: 'Waiting for Response',
      doc: getTimeForTask(referral, 'submit')
        ? `Sent ${relativeTimeSince(DateTime.parse(getTimeForTask(referral, 'submit')), { suffix: true })}`
        : 'Sent',
      sign: 'Waiting for Response',
      other: 'Waiting for Providers',
    }),
    reviewing: {
      none: ({ provider_type: type, sent_referral_summary: summary }, { verbose = false } = {}) => numeric(type, {
        auth: numeric(verbose, {
          true: summary.sent > 1 ? 'Re-Open' : (summary.unavailable === 1 ? 'Denied' : 'No Response'),
          other: summary.sent > 1 ? 'Needs Review' : (summary.unavailable === 1 ? 'Denied' : 'No Response'),
        }),
        other: 'Needs Review',
      }),
      all: 'Time to Review',
      expired: 'Time to Review',
      response_review: 'Response Review Needed',
    },
    reserving: {
      response: (_, { verbose = false } = {}) => numeric(verbose, {
        true: 'View Responses',
        other: 'Needs Review',
      }),
      pending: 'Time to Reserve',
      complete: 'Needs Review',
    },
    reserved: {
      in_house: ({ provider: { name } = {} } = {}, { verbose = false } = {}) => numeric(verbose, {
        true: `Reserved: ${name}`,
        other: 'Reserved',
      }),
      discharged: ({ provider: { name } = {} } = {}, { verbose = false } = {}) => numeric(verbose, {
        true: `Reserved: ${name}`,
        other: 'Reserved',
      }),
      authorized: 'Approved',
      response: (referral) => (isReferralDoc(referral, 'doc') ? 'Delivered' : 'View Responses'),
    },
    discharging: ({ provider: { name } = {} } = {}, { verbose = false } = {}) => numeric(verbose, {
      true: `Reserved: ${name}`,
      other: 'Reserved',
    }),
    discharged: ({ provider: { name } = {} } = {}, { verbose = false } = {}) => numeric(verbose, {
      true: `Reserved: ${name}`,
      other: 'Reserved',
    }),
    update: {
      received: 'Time to Review',
      pending: 'Time to Review',
    },
    completed: ({ provider: { name } = {} } = {}, { verbose = false } = {}) => numeric(verbose, {
      true: `Reserved: ${name}`,
      other: 'Reserved',
    }),
    cancelled: ({ cancelled_at: date }) => `Cancelled ${date ? DateTime.dayFormat(DateTime.parse(date)) : ''}`,
  },
  receiving: {
    responding: {
      viewed: ({ time_window_closes_at } = {}) => (time_window_closes_at ? `${hoursAndMinutesShort(DateTime.parse(time_window_closes_at))} to Respond` : ''),
      under_review: ({ time_window_closes_at } = {}) => (time_window_closes_at ? `${hoursAndMinutesShort(DateTime.parse(time_window_closes_at))} to Respond` : ''),
    },
    responded: {
      accepted: {
        open: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
          auth: numeric(verbose, {
            true: 'Responded Approved',
            other: 'Responded',
          }),
          other: 'Responded',
        }),
        closed: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
          auth: numeric(verbose, {
            true: 'Responded Approved',
            other: 'Responded',
          }),
          other: 'Responded',
        }),
      },
      declined: {
        open: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
          auth: numeric(verbose, {
            true: 'Responded Declined',
            other: 'Declined',
          }),
          other: 'Declined',
        }),
        closed: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
          auth: numeric(verbose, {
            true: 'Responded Declined',
            other: 'Declined',
          }),
          other: 'Declined',
        }),
      },
    },
    missed: {
      open: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
        auth: numeric(verbose, {
          true: 'Respond Now',
          other: 'Overdue Response',
        }),
        other: 'Overdue Response',
      }),
      closed: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
        auth: numeric(verbose, {
          true: 'You\'ve missed the deadline to respond',
          other: 'Missed Deadline',
        }),
        other: 'Missed Deadline',
      }),
    },
    removed: ({ provider_type: type }, { verbose = false } = {}) => numeric(type, {
      auth: numeric(verbose, {
        auth: 'You\'ve been removed',
        other: 'Complete',
      }),
      other: 'Complete',
    }),
    rejected: {
      open: 'Complete',
      closed: 'Complete',
    },
    accepted: 'Reserved',
    incoming: {
      intake: 'Time to Intake',
      transactional: 'Complete',
    },
    in_care: 'In Your Care',
    discharging: 'Time to Discharge',
    discharged: {
      transactional: 'Complete',
      other: 'Complete',
    },
    update: {
      due: 'Time to Update',
    },
    cancelled: ({ cancelled_at: date }) => `Cancelled ${date ? DateTime.dayFormat(DateTime.parse(date)) : ''}`,
  },
};

export const trReferralPopupMessage = createTranslator({
  prompt: 'Are you sure you want to change providers? This may be a confusing message for the currently reserved provider, so it\'s a good idea to [message them beforehand](#action:send_message_to_provider) when you need to select a new provider.',
});

/** Re-usable translation functions for care dates */
export const trCareDates = createTranslator({
  discharging: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Expected to start service',
      other: 'Starting service',
    },
  },
  dischargedNoIntake: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Service started',
      other: 'Service starting',
    },
  },
  dischargingTo: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Service started',
      other: 'Service starting',
    },
    template: ({ provider }) => text => `${text} to ${provider.name}`,
  },
  dischargedFrom: {
    empty: 'Requested start of service date unknown',
    formatter: {
      after: 'Discharged',
      other: 'Discharging',
    },
    template: ({ provider }) => text => `${text} from ${provider.name}`,
  },
  dischargedFromLocation: {
    empty: 'Requested start of service date unknown',
    formatter: {
      after: 'Discharged from',
      other: 'Discharging from',
    },
    template: ({ provider }) => text => `${text} from ${provider.name}`,
  },
  reserved: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Supposed to start service at home',
      other: 'Service started',
    },
  },
  needsCare: {
    empty: 'No start of care date set',
    formatter: {
      after: 'Supposed to begin care',
      other: 'Needs care',
    },
  },
  acceptedCare: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Expected service to have started',
      other: 'Starting service',
    },
  },
  intakeForCare: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Supposed to begin care',
      other: 'Begins care',
    },
  },
  outpatientCare: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Supposed to receive care',
      other: 'Begins care',
    },
  },
  beginsCare: {
    empty: 'No requested start of service date set',
    formatter: {
      after: 'Began care',
      other: 'Begins care',
    },
  },
  beganCare: {
    empty: 'No requested start of service date set',
    formatter: {
      before: 'Begins care',
      other: 'Began care',
    },
  },
  beganInpatientCare: {
    empty: 'No requested start of service date set',
    formatter: {
      before: 'Service starting',
      other: 'Service started',
    },
  },
  beganInpatientCareFrom: {
    empty: 'No requested start of service date set',
    formatter: {
      before: 'Requesting Start of Service',
      other: 'Requested Start of Service',
    },
  },
  completedCare: {
    empty: 'No completed care date set',
    formatter: {
      before: 'Completes care',
      other: 'Completed care',
    },
  },
  leavingCare: {
    empty: 'No requested start of service date set',
    formatter: {
      other: 'Leaving your care',
      after: 'Supposed to have left your care',
    },
  },
  leftCare: {
    empty: 'No discharge date set',
    formatter: {
      before: 'Leaving your care',
      other: 'Left your care',
    },
  },
  cancelled: {
    empty: 'Unknown cancellation date',
    formatter: {
      other: 'Cancelled',
    },
  },
});

/** First descriptive line, a context-sensitive date relative to patient care */
export const dashboardCareDate = {
  sending: {
    cancelled: trCareDates('cancelled'),
  },
  receiving: {
    cancelled: trCareDates('cancelled'),
  },
};

export const referralsDashboardCareDate = {
  sending: {
    drafted: trCareDates('discharging'),
    sending: trCareDates('discharging'),
    sent: trCareDates('discharging'),
    reviewing: {
      none: trCareDates('discharging'),
      all: trCareDates('discharging'),
      expired: trCareDates('discharging'),
    },
    reserving: {
      pending: trCareDates('discharging'),
      complete: trCareDates('discharging'),
    },
    reserved: {
      in_house: trCareDates('discharging'),
      discharged: trCareDates('dischargedNoIntake'),
    },
    discharging: trCareDates('dischargingTo'),
    discharged: trCareDates('dischargingTo'),
    cancelled: trCareDates('cancelled'),
  },
  receiving: {
    responding: {
      viewed: trCareDates('needsCare'),
      under_review: trCareDates('needsCare'),
    },
    responded: {
      accepted: {
        open: trCareDates('needsCare'),
        closed: trCareDates('needsCare'),
      },
    },
    missed: {
      open: trCareDates('needsCare'),
    },
    accepted: trCareDates('acceptedCare'),
    incoming: {
      intake: trCareDates('beginsCare'),
      transactional: trCareDates('needsCare'),
    },
    in_care: trCareDates('beginsCare'),
    discharging: trCareDates('leftCare'),
    discharged: {
      transactional: trCareDates('completedCare'),
      other: trCareDates('leftCare'),
    },
    update: {
      due: trCareDates('beginsCare'),
    },
    cancelled: trCareDates('cancelled'),
  },
};

/** Note: These keys line up with tokens from `withReferralActions` */
export const dashboardMenu = {
  new_for_patient: 'Create new referral for patient',
  copy_partial: 'Copy referral',
  copy_full: 'Copy referral and partners',
  cancel: 'Cancel referral',
  see_all_for_patient: 'See all referrals for this patient',
  assign_followers: 'See & assign followers',
  snooze: 'Snooze referral',
  blind_referral: 'Hide patient demographics',
  unblind_referral: 'Unhide patient demographics',
  mrn: value => `MRN #: ${value}`,
  change_dates_and_deadline: 'Change dates or deadline',
  change_patient_visit: 'Change patient visit',
  send_to_epic: 'Send to Medical Records',
  assign_patient: 'Assign to patient',
};

const statusText = {
  preApprove: 'Pre-approve',
  dashboard: 'Back to my Dashboard',
  practice: 'Practice: Patient Chose You',
};

const createPracticeActionHandler = context => ({ practice }) => numeric(context, {
  actions: practice === 'receiving' ? statusText.practice : statusText.dashboard,
  other: '',
});

const createRespondingActionTranslation = ({
  provider_type: type,
  getProviderRoles,
  getInvitableProviders,
}) => numeric(type, {
  auth: 'Respond',
  other: numeric(getProviderRoles().length + getInvitableProviders().length, {
    1: statusText.preApprove,
    other: 'Respond',
  }),
});

export const createReceivingActionsTranslator = context => createTranslator({
  responding: {
    viewed: createRespondingActionTranslation,
    under_review: createRespondingActionTranslation,
  },
  responded: {
    accepted: {
      open: createPracticeActionHandler(context),
      closed: createPracticeActionHandler(context),
      authorized: createPracticeActionHandler(context),
    },
    declined: {
      open: createPracticeActionHandler(context),
      closed: createPracticeActionHandler(context),
      authorized: createPracticeActionHandler(context),
    },
  },
  missed: {
    open: createRespondingActionTranslation,
    closed: statusText.dashboard,
  },
  expired: 'Log In',
  removed: statusText.dashboard,
  cancelled: statusText.dashboard,
  rejected: {
    open: statusText.dashboard,
    closed: statusText.dashboard,
  },
  accepted: statusText.dashboard,
  incoming: {
    intake: 'Intake Patient',
    transactional: 'Care Complete',
  },
  in_care: statusText.dashboard,
  updated: statusText.dashboard,
  updating: statusText.dashboard,
  update: statusText.dashboard,
  discharging: referral => `Confirm discharge for ${referral.patient.first}`,
  discharged: {
    other: statusText.dashboard,
    transactional: statusText.dashboard,
  },
});

/** Translation functions for decline reason groups */
export const declineReasonGroup = {
  clinically_not_appropriate: 'Clinically not appropriate',
  financial_mismatch: 'Financial mismatch',
  resources_mismatch: 'Resources mismatch',
  hospital_or_patient_choice: 'Hospital or patient choice',
};

export const declineReason = {
  no_bed: 'No bed',
  staff_shortable: 'Staff shortage',
  specialty_provider_unavailable: 'Specialty provider unavailable',
  service_area: 'Out of our service area',
  item_not_offered: 'Item not offered',
  questionable_disp_plan: 'Questionable dispo plan',
  does_not_meet_criteria: 'Does not meet criteria',
  declined_care_needs: 'Care needs exceed capacity',
  declined_insurance: 'Insurance mismatch',
  on_service_with_another_provider: 'On service with another provider',
  insufficient_documentation: 'Insufficient documentation',
  unable_to_accommodate: 'Unable to accommodate requested clinical needs',
  delegate_carveout: 'Delegate/Carveout',
  insufficient_clinical_documentation: 'Insufficient clinical documentation',
  not_covered_by_plan: 'Not covered by plan',
  incorrect_dates: 'Incorrect dates',
  incorrect_level_of_care: 'Incorrect Level of Care',
  not_medically_necessary: 'Not medically necessary',
  patient_maximum_reached: 'Patient maximum reached',
  prior_auth_not_obtained: 'Prior authorization not obtained',
  out_of_network: 'Use of Out of Network provider',
  claim_form_errors: 'Claim form errors',
  claim_deadline_passed: 'Deadline to submit claim passed / delayed submission',
  other: 'Other',
  transition_concerns: 'Concern about transition to next level of care',
  policy_compliance: 'Noncompliant with agency / facility policy',
  no_payor_source: 'No payor source',
  mental_health_concerns: 'Behavioral / mental health concerns',
  medically_complex: 'Medically too complex',
  bad_debt: 'Bad debt / owes facility money',
  payor_not_accepted: 'Payor not accepted at this facility',
  equipment_shortable: 'Lack of equipment resources',
  discharged_elsewhere: 'Discharged to another facility',
  request_cancelled: 'Cancelled by requesting facility',
  known_elsewhere: 'Known with other agency / facility',
  no_following_physician: 'No following physician',
  declined_care_with_this_facility: 'Patient declined care with this facility',
  homeless: 'Homeless',
  needs_long_term_bed: 'Needs long term bed',
  discharge_plan_safety_concern: 'Discharge plan safety concern',
};

export const denialStatuses = {
  received: 'Received',
  pending: 'Pending',
  resolved: 'Resolved',
};

export const approvedText = {
  docReferralAvailable: 'Responded',
  available: 'Pre-approved',
  action: 'Pre-approve',
};

export const removeReason = {
  removed_patient_choice: 'Patient Choice',
  removed_insurance: 'Insurance Mismatch',
  removed_care_needs: 'Care Needs Mismatch',
};

export const dischargeDisposition = {
  discharged_home: 'Discharged home from',
  discharged_deceased: 'Marked as deceased by',
  discharged_home_with_services: 'Discharged home with additional services from',
  discharged_care_plan: 'Discharged with a care plan from',
  discharged_unknown: (_, { category }) => numeric(category, {
    inpatient: 'Discharged from ',
    other: 'Ended care with ',
  }),
  discharged_to_unknown_provider: ({ level_of_care: key = [] } = {}) => numeric(key[0], () => {
    const [type] = key;
    switch (type) {
      case 'ALF':
      case 'IRF':
      case 'LTAC':
      case 'ACUTE':
        return `Discharged on to an ${type} provider from`;
      default:
        return `Discharged on to a ${type} provider from`;
    }
  }),
  discharged_to_provider: ({ name, level_of_care: type = [] } = {}, { color = 'inherit' } = {}) => (
    `Discharged on to <span style="color:${color}">${name}${type && type.length > 0 ? ` (${type[0].toUpperCase()})` : ''}</span> from`
  ),
  discharged_to_unknown_hospital: 'Discharged on to acute care from',
  discharged_hospital: ({ name } = {}, { color = 'inherit' } = {}) => `Discharged on to <span style="color:${color}">${name}</span> from`,
  discharged_other: (_, { category }) => numeric(category, {
    inpatient: 'Discharged from ',
    other: 'Ended care with ',
  }),
};

export const transactionStatus = (levelOfCare, date, providerName, role = 'receiving') => {
  const color = numeric(role, {
    receiving: 'RECEIVING_DARKER_FILL',
    sending: 'SENDING_DARKER_FILL',
  });
  const name = numeric(role, {
    receiving: 'your team',
    other: providerName,
  });
  return numeric(levelOfCare, {
    INF: `Medication delivered by <span style="color:${color}">${name}</span> ${date}`,
    TRNS: `Transportation completed by <span style="color:${color}">${name}</span> ${date}`,
    DME: `Medical equipment delivered by <span style="color:${color}">${name}</span> ${date}`,
    other: `Patient received care from <span style="color:${color}">${name}</span> ${date}`,
  });
};

const modifiesReceivingIntakeDate = [
  'accepted',
  'incoming.intake',
  'incoming.transactional',
  'in_care',
];

export const dischargeLabelText = {
  sending: {
    intake_date: {
      home_service: 'Edit the patient\'s requested start of service date',
      inpatient: 'Edit the patient\'s requested start of service date',
      transactional: 'Edit the patient\'s requested start of service date',
      outpatient: 'Edit the patient\'s requested start of service date',
    },
    discharge_date: {
      home_service: 'Edit the patient\'s discharge date',
      inpatient: 'Edit the patient\'s discharge date',
      transactional: 'Edit the patient\'s discharge date',
      outpatient: 'Edit the patient\'s discharge date',
    },

  },
  receiving: {
    intake_date: {
      home_service: 'Edit the patient\'s start of care date',
      inpatient: 'Edit the patient\'s intake date',
      transactional: 'Edit the patient\'s date of service',
      outpatient: 'Edit the patient\'s date of service',
    },
    discharge_date: {
      home_service: 'Edit the patient\'s end of service date',
      inpatient: 'Edit the patient\'s discharge date',
      transactional: 'Edit the patient\'s end of service date',
      outpatient: 'Edit the patient\'s end of service date',
    },
  },
};

const tr = createTranslator(dischargeLabelText);

/*
The following structure would be ideal if the text is eventually conditional
based on reservationStatus. Currently, the reservationStatus is not used to determine render.

accept_later: numeric(category, {
  [PROVIDER_GROUP_HOME_SERVICE]: numeric(reservationStatus, {
    accepted: tr('receiving.intake_date.home_service'),
    other: tr('receiving.intake_date.home_service'),
  }),
  [PROVIDER_GROUP_INPATIENT]: numeric(reservationStatus, {
    accepted: tr('receiving.intake_date.inpatient'),
    other: tr('receiving.intake_date.inpatient'),
  }),
  [PROVIDER_GROUP_TRANSACTIONAL]: numeric(reservationStatus, {
    accepted: tr('receiving.intake_date.transactional'),
    other: tr('receiving.intake_date.transactional'),
  }),
  [PROVIDER_GROUP_OUTPATIENT]: numeric(reservationStatus, {
    accepted: tr('receiving.intake_date.outpatient'),
    other: tr('receiving.intake_date.outpatient'),
  }),
}),
*/

const sendingIntakeLabels = category => numeric(category, {
  [PROVIDER_GROUP_HOME_SERVICE]: tr('sending.intake_date.home_service'),
  [PROVIDER_GROUP_INPATIENT]: tr('sending.intake_date.inpatient'),
  [PROVIDER_GROUP_TRANSACTIONAL]: tr('sending.intake_date.transactional'),
  [PROVIDER_GROUP_OUTPATIENT]: tr('sending.intake_date.outpatient'),
  other: tr('sending.intake_date.transactional'),
});

const sendingDischargeLabels = category => numeric(category, {
  [PROVIDER_GROUP_HOME_SERVICE]: tr('sending.discharge_date.home_service'),
  [PROVIDER_GROUP_INPATIENT]: tr('sending.discharge_date.inpatient'),
  [PROVIDER_GROUP_TRANSACTIONAL]: tr('sending.discharge_date.transactional'),
  [PROVIDER_GROUP_OUTPATIENT]: tr('sending.discharge_date.outpatient'),
  other: tr('sending.discharge_date.transactional'),
});

export const trChangeDischargeDate = (
  category, referralRole, reservationStatus, actionKey,
) => numeric(referralRole, {
  sending: numeric(actionKey, {
    discharge_later: sendingIntakeLabels(category),
    already_discharged: sendingDischargeLabels(category),
    not_discharged_yet: sendingIntakeLabels(category),
    change_discharge_date: sendingIntakeLabels(category),
    other: sendingIntakeLabels('other'),
  }),
  receiving: numeric(actionKey, {
    accept_later: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.intake_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.intake_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.intake_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.intake_date.outpatient'),
    }),
    patient_arriving_later: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.intake_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.intake_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.intake_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.intake_date.outpatient'),
    }),
    patient_receive_later: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.intake_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.intake_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.intake_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.intake_date.outpatient'),
    }),
    intake_not_complete: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.intake_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.intake_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.intake_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.intake_date.outpatient'),
    }),
    patient_already_received_care: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.intake_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.intake_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.intake_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.intake_date.outpatient'),
    }),
    provider_discharge_later: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.discharge_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.discharge_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.discharge_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.discharge_date.outpatient'),
    }),
    still_in_care: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.discharge_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.discharge_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.discharge_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.discharge_date.outpatient'),
    }),
    early_discharge: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.discharge_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.discharge_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.discharge_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.discharge_date.outpatient'),
    }),
    care_not_complete: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: tr('receiving.discharge_date.home_service'),
      [PROVIDER_GROUP_INPATIENT]: tr('receiving.discharge_date.inpatient'),
      [PROVIDER_GROUP_TRANSACTIONAL]: tr('receiving.discharge_date.transactional'),
      [PROVIDER_GROUP_OUTPATIENT]: tr('receiving.discharge_date.outpatient'),
    }),
    other: numeric(category, {
      [PROVIDER_GROUP_HOME_SERVICE]: `${modifiesReceivingIntakeDate.some(rs => rs === reservationStatus)
        ? tr('receiving.intake_date.home_service')
        : tr('receiving.discharge_date.home_service')}`,
      [PROVIDER_GROUP_INPATIENT]: `${modifiesReceivingIntakeDate.some(rs => rs === reservationStatus)
        ? tr('receiving.intake_date.inpatient')
        : tr('receiving.discharge_date.inpatient')}`,
      [PROVIDER_GROUP_TRANSACTIONAL]: `${modifiesReceivingIntakeDate.some(rs => rs === reservationStatus)
        ? tr('receiving.intake_date.transactional')
        : tr('receiving.discharge_date.transactional')}`,
      [PROVIDER_GROUP_OUTPATIENT]: `${modifiesReceivingIntakeDate.some(rs => rs === reservationStatus)
        ? tr('receiving.intake_date.outpatient')
        : tr('receiving.discharge_date.outpatient')}`,
    }),
  }),
});

export const getChangeDischargeDateLabel = (
  referral, actionKey,
) => getReferralProviderTypeModels(referral)
  .reduce((_, {
    category,
  }) => trChangeDischargeDate(
    category, referral.referralRole, referral.reservationStatus, actionKey,
  ), undefined);

export const requiredFieldMessages = {
  required: 'This field is required',
  requires_currency: 'Please enter a valid cost under $1,000,000 using only numbers',
};

export const patientVisitTypes = {
  inpatient: 'Inpatient',
  outpatient: 'Outpatient',
  emergency: 'Emergency',
  observation: 'Observation',
};
