/* eslint-disable camelcase */

import { createTranslator } from 'helpers/i18n';
import { assemblePatientChoicePDF, generateAttachmentsPDF } from 'helpers/pdf';
import { Date } from 'helpers/dates';
import { trackEvent } from 'helpers/analytics';
import { getUnsentProvidersIds, getAttachmentConfiguration } from 'helpers/referrals';
import GlobalModal, { modals } from 'helpers/GlobalModal';
import {
  getDeclinedCareNeedsReasons,
  getLandingPage,
  isReferralDoc,
  getTasks,
  getPrecertChildReferral,
} from 'models/Referral';
import { isNEMT } from 'models/ProviderType';

/** Set to true if you want to enable Undo features */
const UNDO_ENABLED = false;

/** Error message to use for internally-handled errors */
const HANDLED_ERROR_MESSAGE = 'ReferralActionEngine.error.handled';

export const links = createTranslator({
  dashboard: '/',
  back_to_dashboard: '/',
  open_referral: id => `/referrals/${id}`,
  change_provider_patient: id => `/referrals/${id}/providers`,
  search_for_providers: id => `/referrals/${id}/providers`,
  find_new_providers: id => `/referrals/${id}/providers`,
  see_all_for_patient: id => `/referrals?patient_id=${id}`,
  preview_patient_choice_pdf: (id, locale) => `/referrals/${id}/pdfs/list${locale && locale !== 'en' ? `?printLocale=${locale}` : ''}`,
  admin_provider_networks_edit: id => `/admin/locations/networks/${id}`,
  print_provider_overview: id => `/referrals/${id}/provider-overview`,
  notification_settings: '/admin#notifications',
  open_inbound_document: id => `/inbox/inbound/requests/${id}/new`,
});

/**
 * The engine behind creating and executing referral actions
 * based on strings.
 *
 * Create a new engine by passing it a referral and set of
 * actions. Optionally set handler functions to respond to
 * events such as starting/ending action execution, errors,
 * and redirect requests. Once configured, call the `handle`
 * function to begin processing the action.
 *
 * Callers are responsible for their own component state
 * management, loading screens, and error message display.
 */
class ReferralActionEngine {
  constructor(referral, actions) {
    this.referral = referral;
    this.actions = actions;
    this.errorHandler = this.noop;
    this.caughtErrorHandler = this.noop;
    this.executeBeginHandler = (_, cb) => { cb(); };
    this.executeCompleteHandler = this.noop;
    this.redirectHandler = this.noop;
    this.unsupportedActionHandler = this.noop;
  }

  /**
   * Pass a function that takes an action and a no-arg callback.
   * Call the callback when you are ready to begin the action.
   * This is a good place to set loading states in components.
   */
  setExecuteBeginHandler = (func) => {
    const defaultValue = (_, cb) => { cb(); };
    this.executeBeginHandler = func || defaultValue;
    return this;
  }

  /**
   * The action has completed. This is a good place to stop loading states.
   * The func will be passed the action name.
   */
  setExecuteCompleteHandler = (func) => {
    this.executeCompleteHandler = func || this.noop;
    return this;
  }

  /**
   * The action failed due to error. You may want to disable loading state here.
   * The func will be passed the error.
   */
  setErrorHandler = (func) => {
    this.errorHandler = func || this.noop;
    return this;
  }

  /**
   * The action failed due to an error that is being handled internally. You do
   * not need to take further action, but the error wil be passed to the given
   * func for informational purposes.
   */
  setCaughtErrorHandler = (func) => {
    this.caughtErrorHandler = func || this.noop;
    return this;
  }

  /**
   * Handle requests for redirects. The func will be passed the URL to go to.
   */
  setRedirectHandler = (func) => {
    this.redirectHandler = func || this.noop;
    return this;
  }

  /**
   * How to handle actions that are "coming soon" or unsupported.
   */
  setUnsupportedActionHandler = (func) => {
    this.unsupportedActionHandler = func || this.noop;
    return this;
  }

  noop = () => { }

  /**
   * Internal function. Executes an async action call.
   */
  execute = (action, func) => func()
    .then((...args) => { this.executeCompleteHandler(action, ...args); return args; })
    .catch((e) => {
      if (e.message !== HANDLED_ERROR_MESSAGE) {
        if (this.errorHandler) {
          this.errorHandler(e, action);
        }
      } else if (this.caughtErrorHandler) {
        this.caughtErrorHandler(e, action);
      }
    });

  /**
   * Internal function. Executes a sync action call. The function
   * you pass will be called with onSuccess and onError handlers
   * which hook into the engine.
   */
  executeSync = (action, func) => func(
    (...args) => {
      this.executeCompleteHandler(action, ...args); return args;
    },
    (e) => {
      if (e) {
        if (e.message !== HANDLED_ERROR_MESSAGE) {
          if (this.errorHandler) {
            this.errorHandler(e, action);
          }
        } else if (this.caughtErrorHandler) {
          this.caughtErrorHandler(e, action);
        }
      } else {
        this.errorHandler(null, action);
      }
    },
  );

  /**
   * Call this to handle an action
   */
  handle = (action, ...params) => {
    const { id, reservationStatus, patient: { id: patientId, first, last } = {} } = this.referral;
    switch (action) {
      case 'dashboard':
      case 'back_to_dashboard':
      case 'search_for_providers':
      case 'print_provider_overview':
      case 'find_new_providers':
      case 'change_provider_patient':
      case 'notification_settings':
        this.redirectHandler(links(action, id));
        break;
      case 'open_referral':
        this.redirectHandler(links(action, id), ...params);
        break;
      case 'see_all_for_patient':
        this.redirectHandler(links(action, patientId, `${first}+${last}`));
        break;
      case 'preview_patient_choice_pdf': {
        const [printLocale] = params;
        window.open(links(action, id, printLocale), '_blank');
        break;
      }
      case 'provider_request_form':
        window.location.assign('http://www.myaidin.com/sign_up/');
        break;
      case 'send_message':
      case 'requestMessageThreadForReferral':
        this.execute(action, () => (
          this.actions.requestMessageThreadForReferral(this.referral, params && params[0])
        ));
        break;
      case 'send_message_to_provider': {
        this.execute(action, () => (
          this.actions.requestMessageThreadForReferral(this.referral, this.referral.provider)
        ));
        break;
      }
      case 'response_review': {
        const defaultResponse = this.referral.referralResponse || this.referral.sent_referrals[0];
        const [referralResponse = defaultResponse] = params;
        const getDocumentIdForTask = (tasks) => {
          const [docResponse] = tasks.document_responses;
          return docResponse?.response_attachment?.id;
        };

        this.execute(action, () => new Promise((resolve, reject) => {
          const fauxReferral = {
            currentRole: {
              referral: {
                tasks: referralResponse.tasks,
              },
            },
          };
          const tasks = getTasks(fauxReferral, 'response_review');

          const openDocumentList = (onSubmit) => GlobalModal
            .open(modals.TOGGLE_INBOUND_RESPONSE_REVIEW_LIST_MODAL, {
              tasks,
              onSubmit,
            });

          const openForDocument = ({ taskId, documentId }) => {
            if (documentId) {
              const task = tasks.find(i => i.id === taskId);
              GlobalModal.open(modals.TOGGLE_INBOUND_RESPONSE_REVIEW_PROCESSING_MODAL, {
                documentId,
                task,
                referralResponse,
                referral: this.referral,
                onReturnToReviewList: tasks.length > 1
                  ? () => openDocumentList(openForDocument)
                  : undefined,
              }).then(() => resolve()).catch(reject);
            }
          };

          if (tasks.length > 1) {
            openDocumentList(openForDocument);
          } else if (tasks.length === 1) {
            const [task] = tasks;
            openForDocument({ taskId: task.id, documentId: getDocumentIdForTask(task) });
          }
        }));
        break;
      }
      case 'change_response': {
        if (reservationStatus.match(/responded\..*\.closed/)) {
          this.execute(action, () => this.actions.requestMessageThreadForReferral(this.referral));
          break;
        }
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        this.executeBeginHandler(action, () => {
          setTimeout(() => {
            this.handleAsync(action, ...params);
          }, 50);
        });
        break;
      }
    }
  }

  handleAsync = (action, ...params) => {
    const {
      id, patient, provider_id, referralRole, referralRoleOrganization,
      referralResponse, reservationStatus, sent_referral_index, hospital_id,
      patient_visit,
    } = this.referral;
    switch (action) {
      case 'new_for_visit_search': {
        let [options = {}] = params;
        let referralResponseId;
        let visitId;
        if (referralResponse && referralRole === 'receiving') {
          referralResponseId = referralResponse.id;
          visitId = referralResponse.patient_visit.id;
        } else {
          referralResponseId = id;
          visitId = patient_visit.id;
        }
        const { id: pt } = patient;
        options = {
          ...options,
          related_referral_id: id,
          sent_referral_id: referralResponseId,
          patient_visit_id: visitId,
          disableReplaceOnCreate: true,
        };
        const { searchOnLoad = false } = options;
        this.execute(action, () => this.actions.createReferral(pt, options)).then((resp) => {
          const [newReferral = {}] = resp;
          // session refresh
          this.actions.requestSession().then(() => {
            if (newReferral.id) {
              this.redirectHandler(getLandingPage(newReferral, { isNew: true, path: 'providers' }), { searchOnLoad });
            }
          });
        });
        break;
      }
      case 'create_precert_sending': {
        const attachment_id = this.referral.attachments.filter(attachment => !attachment.generated)
          .map(attachment => attachment.id);
        const startOfCare = this.referral.getReceivingStartOfCare();
        const endOfCare = this.referral.getReceivingEstimatedDischargeDate();
        const options = {
          provider_type: 'auth',
          role: 'sending',
          attachment_id,
          start_of_care: startOfCare,
          discharge_date: startOfCare,
          end_of_care: endOfCare,
          drop_off_provider_id: provider_id,
          searchOnLoad: true,
        };
        this.handleAsync('new_for_visit_search', options);
        break;
      }
      case 'view_precert_auth_request': {
        const precertChildReferral = getPrecertChildReferral(this.referral);
        if (precertChildReferral) {
          this.redirectHandler(getLandingPage({ ...precertChildReferral, id: precertChildReferral.child_id }));
        }
        break;
      }
      case 'new_document_for_patient_visit': {
        const [{
          defaultValues = {},
          defaultValues: { patient: selectedPatient } = {},
          patient_visit: selectedVisit,
        }] = params;
        this.execute(action, () => this.actions.handleSendToProvider({ patient: selectedPatient, patient_visit: selectedVisit, organization: defaultValues.organization }, [], defaultValues));
        break;
      }
      case 'new_document_for_patient': {
        const [defaultValues, selectedPatient] = params;
        if (selectedPatient) {
          this.execute(action, () => this.actions.openPatientVisitChooserModal(
            selectedPatient,
            {
              allowSkipOnSingleVisit: true,
              referralAction: 'new_document_for_patient_visit',
              defaultValues: {
                ...(defaultValues || {}),
                patient: selectedPatient,
              },
            },
          ));
        } else if (defaultValues && defaultValues.organization) {
          this.execute(action, () => this.actions.handleSendToProvider({ organization: defaultValues.organization }, [], defaultValues));
        }
        break;
      }
      case 'new_for_patient':
      case 'copy_partial':
      case 'copy_full': {
        let [options] = params;
        if (action === 'copy_partial' || action === 'copy_full') {
          const [, mode] = action.split('_');
          options = {
            ...options, referralAction: action, template: id, mode,
          };
        }
        if (action === 'new_for_patient' && options) {
          options = { ...options, ...options.entity };
        }
        const { noRedirect = false } = options || {};
        this.execute(action, () => this.actions
          .createReferral(patient.id, options)
          .catch((e) => {
            if (e.status === 409 && this.actions.openCreateReferralConflictModal) {
              /*
               * TODO: If we wanted to handle multiple sending locations for a single
               * Provider, a conflict would be caught here that could prompt the user
               * to choose one of the sending locations in the entity.
               */
              this.actions.openCreateReferralConflictModal(patient, options, e.response.entity);
            }
            throw new Error(HANDLED_ERROR_MESSAGE);
          }))
          .then(([newReferral = {}] = []) => {
            if (newReferral.id) {
              GlobalModal.close(modals.TOGGLE_NEW_REFERRAL_MODAL);
              if (!noRedirect) {
                this.redirectHandler(getLandingPage(newReferral, { isNew: true }));
              }
            }
          });
        break;
      }
      case 'change_patient_visit': {
        this.execute(action, () => this.actions.openPatientVisitChooserModal(
          patient,
          {
            id,
            referralAction: 'update_patient_visit_id',
            patient_visit_id: this.referral.patient_visit_id,
          },
        ));
        break;
      }
      case 'patient_never_arrived':
        this.execute(action, () => (
          this.actions.sendAvailableNotes(
            params[0], referralRoleOrganization, patient.hospital, this.referral,
          )
        ));
        break;
      case 'cancel':
        this.execute(action, () => this.actions.updateReferralStatus(id, { status: 'canceled', cancel_reason: params[0] }));
        break;
      case 'change_discharge_date': {
        const [data] = params;
        const payload = {
          discharge_date: data.dischargeDate || undefined,
          start_of_care: data.startOfCare || undefined,
        };
        if (data.hospitalAnticipatedDischargeDate !== undefined) {
          payload.hospital_anticipated_discharge_date = data.hospitalAnticipatedDischargeDate;
        }
        this.execute(action, () => this.actions.updateReferral(id, payload));
        break;
      }
      case 'send_document': {
        const [attachmentId, referralId, provider, saveProvider] = params;
        this.execute(action, () => this.actions.sendDocument({
          attachmentId, provider, saveProvider, referralId,
        }));
        break;
      }
      case 'create_outbound_document_referral': {
        const [hospital, files, meta, opts] = params;
        this.execute(action, () => this.actions.createOutboundDocumentReferral(
          hospital, files, meta, opts,
        ));
        break;
      }
      case 'toggle_availity_auto_updates': {
        const [data] = params;
        const { contactLogId, shouldStopAutoStatusUpdate } = data || {};
        this.execute(action, () => (
          this.actions.toggleAvailityAutomaticStatusUpdate(contactLogId, shouldStopAutoStatusUpdate)
        ));
        break;
      }
      case 'send_manual': {
        const [sr, mode, data] = params;
        const conflictHandler = (e) => {
          if (e.status === 409) {
            switch (e.message) {
              case 'full_fax_disabled': {
                GlobalModal.open(modals.TOGGLE_FULL_FAX_DISABLED_CONFLICT_MODAL, {
                  faxPreferences: e.response.entity,
                  onSubmit: (override) => {
                    this.execute(action, () => this.actions.renotifySentReferral(sr, mode, data, override));
                    GlobalModal.close(modals.TOGGLE_FULL_FAX_DISABLED_CONFLICT_MODAL);
                  },
                  handleClose: () => {
                    GlobalModal.close(modals.TOGGLE_FULL_FAX_DISABLED_CONFLICT_MODAL);
                  },
                });
                break;
              }
              case 'PayorPortal': {
                // FIXME: DRY
                const {
                  requirements,
                  payorPortalService,
                  payorPortalService: {
                    id: payorPortalServiceId,
                  },
                  provider_search_result_contact_detail: details,
                  provider,
                } = e.response.entity;
                const handleClose = () => GlobalModal.close(modals.TOGGLE_PROVIDER_CONFLICT_MODAL);
                GlobalModal.open(modals.TOGGLE_PROVIDER_CONFLICT_MODAL, {
                  requirements,
                  payorPortalService,
                  provider,
                  providerContactDetails: details,
                  onSubmit: ({ payorJSONResponse }) => this.execute(action, () => (
                    this.actions.renotifySentReferral(sr, mode, {
                      payor_portal: {
                        ...data.payor_portal,
                        ...payorJSONResponse,
                        payorPortalServiceId,
                      },
                    }).catch(conflictHandler)
                  )),
                  onClose: handleClose,
                  onCancel: handleClose,
                });
                break;
              }
              default:
                break;
            }
            throw new Error(HANDLED_ERROR_MESSAGE);
          } else {
            throw e;
          }
        };
        this.execute(action, () => this.actions.renotifySentReferral(sr, mode, data)
          .catch(conflictHandler));
        break;
      }
      case 'check_for_payor_portal_updates': {
        const [sentReferralId, sentReferralContactLogId] = params;
        this.execute(action, () => (
          this.actions.checkForPayorPortalUpdates(sentReferralId, sentReferralContactLogId)
        ));
        break;
      }
      case 'send_to_providers': {
        const [options] = params;
        const unsent = getUnsentProvidersIds(
          this.referral,
          this.referral.provider_search.provider_search_results || [],
          item => item.provider_id,
        );
        this.executeSync(action, (onSuccess, onError) => {
          this.actions.sendToProviders(this.referral, unsent, options, onSuccess, onError);
        });
        break;
      }
      case 'skip_to_drafted':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'new_patient',
        }));
        break;
      case 'skip_to_sent':
      case 'change_deadline':
      case 'extend_deadline': {
        const [deadline, additionalData] = params;
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          ...additionalData,
          status: 'awaiting_provider_responses',
          time_window_closes_at: deadline,
        }).then(this.makeUndoable(action)));
        break;
      }
      case 'generate_patient_choice_pdf': {
        const [printLocale] = params;
        this.execute(action, () => (
          assemblePatientChoicePDF(this.referral, { printLocale, ...this.actions })
        ));
        break;
      }
      case 'forward_patient_choice_pdf': {
        const [sendingInfo, updateRequired = false, printLocale] = params;
        const maybeDoUpdate = () => (
          updateRequired && this.actions.updateReferralStatus(id, { status: 'awaiting_patient' })
        );
        const { sendToProvider = false } = sendingInfo;
        this.execute(action, () => new Promise((resolve, reject) => {
          if (sendToProvider) {
            const key = printLocale === 'es' ? 'patientChoicePDFSpanish' : 'patientChoicePDF';
            const attachments = [getAttachmentConfiguration(key, this.referral)];
            this.actions.handleSendToProvider({ patient, patient_visit, organization: this.referral.hospital }, attachments, {
              recipient: 'provider', onComplete: maybeDoUpdate, parentReferral: this.referral, deadline: this.referral.discharge_date,
            })
              .then(resolve).catch(reject);
          } else {
            assemblePatientChoicePDF(this.referral, { printLocale, ...this.actions }, sendingInfo)
              .then(maybeDoUpdate)
              .then(resolve).catch(reject);
          }
        }));
        break;
      }
      case 'print_patient_choice_pdf':
        this.execute(action, () => this.actions.updateReferralStatus(id, { status: 'awaiting_patient' }));
        break;
      case 'reprint_patient_choice_pdf':
        this.executeCompleteHandler(action);
        break;
      case 'forward_attachment': {
        const [attachments, emails, from] = params;
        this.execute(action, () => (
          generateAttachmentsPDF(this.referral, attachments, this.actions, { to: emails, from })
        ));
        break;
      }
      case 'reserve_provider':
      case 'reserve_provider_again':
      case 'complete_response': {
        if (isReferralDoc(this.referral)) {
          const [reservedProviderId] = params;
          this.execute(
            action,
            () => this.actions.markResponseRequestAsComplete(id, reservedProviderId),
          );
        } else {
          const [reservedProviderId, data] = params;
          const entity = {
            status: 'provider_chosen',
            provider_id: reservedProviderId,
          };
          if (data) {
            const { dischargeDate, startOfCare, practice } = data;
            entity.discharge_date = dischargeDate;
            entity.start_of_care = startOfCare;
            entity.practice = practice;
          }
          this.execute(action, () => this.actions.updateReferralStatus(id, entity)
            .then(this.makeUndoable(action)));
        }
        break;
      }
      case 'remove_provider': {
        /*
         * TODO: to make undo-able, save sent referral providers so they can be
         * added back later.
         */
        const [sr, removeData = {}] = params;
        const organization = !referralRoleOrganization ? {} : {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        this.execute(action, () => this.actions.updateSentReferralStatus(sr, {
          status: 'under_review',
          removed_at: Date.utc(Date.now()),
          remove_reason: 1,
          session_token: null,
          confirmed: false,
          ...removeData,
        }, organization, { referralRole }));
        break;
      }
      case 'unremove_provider': {
        const [sr] = params;
        this.execute(action, () => this.actions.updateSentReferralStatus(sr, {
          status: 'pending',
          removed_at: null,
          remove_reason: null,
          confirmed: true,
        }));
        break;
      }
      case 'not_discharged_yet':
      case 'discharge_later':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'provider_chosen',
          provider_id,
          provider_actual_received_date: null,
          provider_anticipated_discharge_date: null,
          provider_anticipated_received_date: params[0].dischargeDate || undefined,
          discharge_date: params[0].dischargeDate || undefined,
          start_of_care: params[0].startOfCare || undefined,
          indefinite_service: false,
        }).then(this.makeUndoable(action)));
        break;
      case 'patient_arriving_later':
      case 'patient_receive_later':
      case 'patient_arrive_later':
      case 'accept_later':
      case 'care_not_complete':
      case 'intake_not_complete':
      case 'services_starting_on_different_date':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'provider_chosen',
          provider_id,
          provider_actual_received_date: null,
          provider_anticipated_discharge_date: null,
          provider_anticipated_received_date: params[0].dischargeDate || undefined,
          start_of_care: params[0].startOfCare || undefined,
          indefinite_service: false,
        }).then(this.makeUndoable(action)));
        break;
      case 'skip_to_discharging':
      case 'skip_to_discharged':
      case 'discharge_now': {
        const dcTask = this.referral.getTask('discharge', false);
        if (dcTask) {
          this.execute(action, () => this.actions.updateReferralTask(id, dcTask.id, {
            completed_at: Date.utc(Date.now()),
          }, {
            discharge_date: Date.utc(Date.now()),
          }).then(this.makeUndoable(action)));
        } else {
          throw new Error('Patient discharge not available');
        }
        break;
      }
      case 'already_discharged': {
        const dcTask = this.referral.getTask('discharge', false);
        if (dcTask) {
          this.execute(action, () => this.actions.updateReferralTask(id, dcTask.id, {
            completed_at: Date.utc(Date.now()),
          }, {
            discharge_date: params[0].dischargeDate || undefined,
            start_of_care: params[0].startOfCare || undefined,
          }).then(this.makeUndoable(action)));
        } else {
          throw new Error('Patient discharge not available');
        }
        break;
      }
      case 'update_adt_discharge': {
        const dcTask = this.referral.getTask('discharge', true);
        if (dcTask) {
          this.execute(action, () => this.actions.updateReferralTask(id, dcTask.id, {
            completed_at: dcTask.completed_at || Date.utc(Date.now()),
          }, {
            discharge_date: params[0].dischargeDate || undefined,
            start_of_care: params[0].startOfCare || undefined,
          }).then(this.makeUndoable(action)));
        } else {
          const {
            patient_visit_id: visitId,
          } = this.referral;
          const dc = params[0].dischargeDate;
          this.execute(action, () => this.actions.updateReferral(id, {
            discharge_date: params[0].dischargeDate || undefined,
            start_of_care: params[0].startOfCare || undefined,
            patient_visit_attributes: (dc && visitId) ? {
              id: visitId,
              discharge: dc,
            } : undefined,
          }));
        }
        break;
      }
      case 'mark_contact_log_failed': {
        const [contactLogId, metadata] = params;
        this.execute(action, () => this.actions.updateContactLogStatus(contactLogId, 'failed', metadata));
        break;
      }
      case 'respond':
      case 'accept': {
        const [responses, options = {}] = params;
        const organization = {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        trackEvent('Referrals', 'Responding', 'Available');

        const handleRespondErrors = (e) => {
          if (e.status === 409) {
            const {
              response: {
                entity: {
                  conflicts = [],
                } = {},
              } = {},
            } = e;

            if (conflicts.some(i => i === 'viewed_documents')) {
              GlobalModal.open(modals.TOGGLE_DOCUMENT_WARNING_MODAL, {
                onSubmit: (resubmit) => {
                  GlobalModal.close(modals.TOGGLE_DOCUMENT_WARNING_MODAL);
                  if (resubmit) {
                    this.handleAsync(action, responses, {
                      onConflict: { viewed_documents: resubmit },
                    });
                  } else {
                    this.errorHandler(e, action);
                  }
                },
              });
              throw new Error(HANDLED_ERROR_MESSAGE);
            }
          } else {
            throw e;
          }
        };

        const createOptions = () => {
          if (referralRole === 'sending' || isReferralDoc(this.referral, 'doc')) {
            return { ...(options || {}), onConflict: { viewed_documents: true } };
          }
          return options;
        };

        if (responses && responses.length > 0 && !isReferralDoc(this.referral)) {
          if (responses.length === 1) {
            const { id: sentReferralId, ...data } = responses[0];
            this.execute(action, () => this.actions.updateSentReferralStatus(sentReferralId, {
              removed_at: null,
              remove_reason: null,
              confirmed: true,
              declined_reason: null,
              declined_reason_other: null,
              pick_up_at: null,
              follow_up_phone_number: null,
              ...data,
            }, organization, createOptions())
              .then(this.makeUndoable(action)).then(() => {
                if (referralRole === 'receiving') {
                  this.actions.requestProviderPreferencesModal(id, [sentReferralId], hospital_id);
                }
              })
              .catch(handleRespondErrors));
          } else {
            // This is a batch operation...
            const batch = responses.map(response => ({
              removed_at: null,
              remove_reason: null,
              confirmed: true,
              ...response,
            }));
            this.execute(action, () => this.actions.batchUpdateSentReferralStatus(batch, createOptions())
              .then(() => {
                const check = [...getDeclinedCareNeedsReasons(), 'declined_insurance', 'service_area', 'other'];
                const confirmationRequired = batch.filter(({ status, declined_reason }) => (
                  status === 'available' || (status === 'unavailable' && declined_reason && check.some(r => r === declined_reason))
                ));
                if (confirmationRequired.length > 0 && referralRole === 'receiving') {
                  this.actions.requestProviderPreferencesModal(id,
                    confirmationRequired.map(sr => sr.id));
                }
              })
              .catch(handleRespondErrors));
          }
        }
        if (isReferralDoc(this.referral, 'sign')) {
          this.handleAsync('document_response', responses);
        } else if (isReferralDoc(this.referral, 'doc')) {
          const { id: sentReferralId, ...data } = responses[0];
          this.execute(action, () => this.actions.updateSentReferralStatus(sentReferralId, {
            removed_at: null,
            remove_reason: null,
            confirmed: true,
            declined_reason: null,
            declined_reason_other: null,
            pick_up_at: null,
            follow_up_phone_number: null,
            ...data,
            status: 'available',
          }, organization, createOptions()));
        }
        break;
      }
      case 'decline': {
        const [response, sentReferralId = referralResponse.id] = params;
        const organization = {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        let declined_reason = null;
        let metadata = {};
        if (typeof response === 'string') {
          declined_reason = response;
        } else {
          declined_reason = response.declined_reason;
          metadata = response;
        }
        const confirmationRequired = [...getDeclinedCareNeedsReasons(), 'declined_insurance', 'service_area', 'other']
          .some(r => r === declined_reason);
        trackEvent('Referrals', 'Responding', 'Decline');
        this.execute(action, () => this.actions.updateSentReferralStatus(sentReferralId, {
          removed_at: null,
          remove_reason: null,
          confirmed: true,
          status: 'unavailable',
          available_notes: null,
          pick_up_at: null,
          ...metadata,
          declined_reason,
        }, organization).then(this.makeUndoable(action)).then(() => {
          if (confirmationRequired && referralRole === 'receiving') {
            this.actions.requestProviderPreferencesModal(id, [sentReferralId]);
          }
        }));
        break;
      }
      case 'mark_as_under_review': {
        const [referralResponseData = referralResponse, data = {}] = params;
        const organization = {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        // TODO: is there more data that needs to be cleared, esp for AUTH/TRNS?
        this.execute(action, () => this.actions.updateSentReferralStatus(referralResponseData.id, {
          removed_at: null,
          remove_reason: null,
          confirmed: true,
          status: 'under_review',
          declined_reason: null,
          available_notes: null,
          start_of_care: null,
          end_of_care: null,
          ...data,
        }, organization).then(this.makeUndoable(action)));
        break;
      }
      case 'skip_to_responded':
      case 'change_response': {
        const [referralResponseData = referralResponse] = params;
        if (reservationStatus.match(/responded\..*\.closed/)) {
          this.execute(action, () => this.actions.requestMessageThreadForReferral(this.referral));
        } else {
          this.execute(action, () => this.actions.updateSentReferralStatus(
            referralResponseData.id, {
              removed_at: null,
              remove_reason: null,
              confirmed: true,
              status: 'under_review',
              declined_reason: null,
              available_notes: null,
            },
          ).then(this.makeUndoable(action)));
        }
        break;
      }
      case 'change_response_for_provider': {
        const [responseId] = params;
        const organization = !referralRoleOrganization ? {} : {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        this.execute(action, () => this.actions.updateSentReferralStatus(responseId, {
          removed_at: null,
          remove_reason: null,
          confirmed: true,
          status: 'under_review',
          declined_reason: null,
          available_notes: null,
        }, organization).then(this.makeUndoable(action)).then(() => (
          (sent_referral_index || []).find(sr => sr.id === responseId) || undefined
        )));
        break;
      }
      case 'revert_to_initial_sent_status': {
        const [responseId] = params;
        const organization = !referralRoleOrganization ? {} : {
          organization_id: referralRoleOrganization.id,
          organization_type: referralRoleOrganization.organization_type,
        };
        const options = {
          revert_to_initial: true,
        };
        // TODO: is there more data that needs to be cleared, esp for AUTH/TRNS?
        this.execute(action, () => this.actions.updateSentReferralStatus(responseId, {
          removed_at: null,
          remove_reason: null,
          confirmed: true,
          status: 'viewed',
          declined_reason: null,
          available_notes: null,
          start_of_care: null,
          end_of_care: null,
          authorization_code: null,
        }, organization, options).then(this.makeUndoable(action)).then(() => (
          (sent_referral_index || []).find(sr => sr.id === responseId) || undefined
        )));
        break;
      }
      case 'create_sent_referral': {
        const [[sentReferral]] = params;
        this.execute(action, () => this.actions.createSentReferral(sentReferral));
        break;
      }
      case 'still_in_care': {
        const entity = {
          status: 'handed_to_provider',
          provider_actual_discharge_date: null,
          provider_anticipated_discharge_date: params[0].dischargeDate,
        };
        if (params[0].dischargeDate && this.referral.indefinite_service) {
          entity.indefinite_service = false;
        }
        this.execute(action, () => this.actions.updateReferralStatus(id, entity)
          .then(this.makeUndoable(action)));
        break;
      }
      case 'provider_declined_services': {
        this.execute(action, () => this.actions.unreserveProvider(id, referralResponse.id, {
          status: 'unavailable',
        }));
        break;
      }
      case 'patient_already_receiving_care':
      case 'patient_already_in_care':
      case 'patient_receive_now':
      case 'skip_to_accepted':
      case 'intake': {
        const [dates = {}] = params;
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'handed_to_provider',
          provider_actual_received_date: Date.utc(Date.now()),
          ...dates,
        }).then(this.makeUndoable(action)));
        break;
      }
      case 'complete_care': {
        this.execute(action, () => new Promise((resolve, reject) => {
          const date = Date.utc(Date.now());
          const updateReferralTransportationStatusAction = () => {
            const updateData = {
              status: 'handed_to_provider',
              provider_anticipated_received_date: date,
              provider_actual_received_date: date,
              provider_anticipated_discharge_date: date,
              provider_actual_discharge_date: date,
            };
            this.actions.updateReferralStatus(id, updateData)
              .then(() => this.makeUndoable(action))
              .then(() => resolve(true))
              .catch(reject);
          };
          if (isNEMT(this.referral.level_of_care)) {
            const onSubmit = (data) => {
              const { id: sentReferralId } = referralResponse;
              this.actions.updateSentReferral(sentReferralId, { ...data })
                .then(updateReferralTransportationStatusAction)
                .catch(reject);
            };
            const modalName = modals.TOGGLE_COMPLETE_NON_EMERGENCY_MEDICAL_TRANSPORT_MODAL;
            GlobalModal.open(modalName, {
              referral: this.referral,
              referralResponse: {
                ...referralResponse,
              },
              onSubmit,
              onClose: (isSubmitted) => GlobalModal.close(modalName).then(() => {
                if (!isSubmitted) { resolve(false); }
              }),
            });
          } else {
            updateReferralTransportationStatusAction();
          }
        }));
        break;
      }
      case 'provider_already_discharged':
      case 'skip_to_provider_discharging':
      case 'provider_discharge_now': {
        const [task] = params;
        this.execute(action, () => this.actions.updateReferralTask(id, task.id, task));
        break;
      }
      case 'patient_already_received_care':
      case 'provider_discharge_later':
      case 'change_provider_anticipated_discharge_date': {
        const [{ dischargeDate, indefinite_service } = {}] = params;
        const entity = {
          provider_anticipated_discharge_date: dischargeDate,
        };
        if (dischargeDate && this.referral.indefinite_service) {
          entity.indefinite_service = false;
        } else if (!dischargeDate && indefinite_service) {
          entity.indefinite_service = true;
        }
        this.execute(action, () => this.actions.updateReferral(id, entity)
          .then(this.makeUndoable(action)));
        break;
      }
      case 'change_provider_discharge_date':
      case 'early_discharge':
        this.execute(action, () => this.actions.updateReferral(id, {
          provider_actual_discharge_date: params[0].dischargeDate,
          indefinite_service: params[0].dischargeDate
            ? false : (this.referral.indefinite_service || false),
        }).then(this.makeUndoable(action)));
        break;
      case 'skip_to_reviewing':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'provider_responses_closed',
        }).then(this.makeUndoable(action)));
        break;
      case 'skip_to_reserved':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'provider_responses_closed',
        }).then(this.makeUndoable(action)));
        break;
      case 'skip_to_reserving':
        this.execute(action, () => this.actions.updateReferralStatus(id, {
          status: 'awaiting_patient',
        }).then(this.makeUndoable(action)));
        break;
      case 'remove_attachment': {
        const [attachmentId, attachmentSource = 'attachments'] = params;
        this.execute(action, () => this.actions
          .removeAttachmentFromReferral(id, referralRoleOrganization,
            attachmentId, attachmentSource));
        break;
      }
      case 'add_attachment': {
        const [files] = params;
        const target = { attachable_id: id, attachable_type: 'Referral' };
        if (referralRole === 'receiving' && referralResponse) {
          target.attachable_id = referralResponse.id;
          target.attachable_type = 'SentReferral';
        }
        this.execute(action, () => this.actions
          .addAttachmentToReferral(id, referralRoleOrganization, files, target));
        break;
      }
      case 'rename_attachment': {
        const [attachments] = params;
        this.execute(action, () => this.actions
          .updateAttachmentOnReferral(id, referralRoleOrganization, attachments));
        break;
      }
      case 'update_attachment': {
        const [file] = params;
        this.execute(action, () => this.actions
          .updateAttachmentOnReferral(id, referralRoleOrganization, file));
        break;
      }
      case 'update_follow_up':
        this.execute(action, () => this.actions.updateReferral(id, {
          follow_ups_attributes: [{
            id: params[0].id,
            consent: params[0].consent,
          }],
        }));
        break;
      case 'update_sponsored_by_hospital':
        this.execute(action, () => this.actions.updateReferral(id, {
          sponsored_by_hospital: params[0].checked,
        }));
        break;
      case 'patient_not_receiving_care':
      case 'patient_not_coming': {
        const [entity] = params;
        if (typeof entity === 'string') {
          if (referralRole === 'sending') {
            this.execute(action, () => this.actions.updateReferralStatus(id, { status: 'canceled', cancel_reason: params[0] }));
          } else if (referralRole === 'receiving') {
            this.execute(action, () => (
              this.actions.sendAvailableNotes(
                entity, referralRoleOrganization, patient.hospital, this.referral,
              )
            ));
          } else {
            this.unsupportedActionHandler(action);
          }
        } else {
          this.execute(action, () => this.actions.createVisibilityRule(entity));
        }
        break;
      }
      case 'snooze': {
        const [data] = params;
        const { metadata, ...entity } = data;
        this.execute(action, () => this.actions.createVisibilityRule(entity, metadata));
        break;
      }
      case 'unfollow_patient': {
        const [patientId] = params;
        this.execute(action, () => this.actions.unfollowPatient(patientId));
        break;
      }
      case 'blind_referral': {
        this.execute(action, () => this.actions.updateReferral(id, { blind: true }));
        break;
      }
      case 'unblind_referral': {
        this.execute(action, () => this.actions.updateReferral(id, { blind: false }));
        break;
      }
      case 'update_patient_visit_id': {
        const [{ id: referralId, patient_visit_id }] = params;
        this.execute(action, () => this.actions.updateReferral(referralId, { patient_visit_id }));
        break;
      }
      case 'document_response': {
        const [response] = params;
        this.execute(action, () => GlobalModal.open(modals.TOGGLE_DOCUMENT_RESPONSE_MODAL, {
          referral: {
            ...this.referral,
            referralResponse: response[0],
          },
        }));
        break;
      }
      case 'generate_document_review': {
        const payload = this.referral.attachments.filter(({ dataSource }) => dataSource === 'attachments');
        this.execute(action, () => (
          generateAttachmentsPDF(this.referral, payload, this.actions)
        ));
        break;
      }
      case 'document_review': {
        // The work has been done by generate; we just need to update the referral
        this.execute(action, () => this.actions.refreshReferral(id));
        break;
      }
      case 'new_document_referral': {
        const [entity] = params;
        const {
          organization: hospital, files, meta, sendOptions, patient_visit_id, onConflict, source,
        } = entity;
        const opts = {
          ...sendOptions,
          onConflict,
          patient_visit_id,
        };

        this.execute(action, () => this.actions.createOutboundDocumentReferral(
          hospital, files, meta, opts,
        ).then((referral) => {
          GlobalModal.open(modals.TOGGLE_SEND_TO_EPIC_MODAL, {
            attachments: referral.attachments,
            referral,
            source,
          });
        }).catch((e) => {
          if (e.status === 409) {
            if (e.message === 'child_request_already_sent') {
              GlobalModal.open(
                modals.TOGGLE_REQUEST_ALREADY_EXISTS,
                {
                  alreadySentBy: e.response.entity.user.name,
                  alreadySentAt: e.response.entity.created_at,
                  alreadySentTo: e.response.entity.alreadySentTo,
                  childReferralId: e.response.entity.child_id,
                  onSubmit: (resubmit) => {
                    GlobalModal.close(modals.TOGGLE_REQUEST);
                    if (resubmit) {
                      this.handleAsync(action, {
                        ...entity,
                        onConflict: resubmit,
                      });
                    } else {
                      this.errorHandler(e, action);
                    }
                  },
                },
              );

              throw new Error(HANDLED_ERROR_MESSAGE);
            } else if (this.actions.openCreateReferralConflictModal) {
              /*
              * TODO: If we wanted to handle multiple sending locations for a single
              * Provider, a conflict would be caught here that could prompt the user
              * to choose one of the sending locations in the entity.
              */
              const conflictOptions = {
                ...entity,
                referralAction: action,
              };
              this.actions.openCreateReferralConflictModal(
                patient, conflictOptions, e.response.entity,
              );

              throw new Error(HANDLED_ERROR_MESSAGE);
            } else {
              throw e;
            }
          } else {
            throw e;
          }
        }));
        break;
      }
      case 'send_to_epic': {
        const [options] = params;
        this.execute(action, () => this.actions.sendToEpic(id, options));
        break;
      }
      case 'reopen_auth_referral': {
        const [timeWindowClosesAt] = params;
        this.execute(action, () => this.actions.reopenAuthReferral(id, timeWindowClosesAt));
        break;
      }
      case 'request_physician_review': {
        const [note] = params;
        const {
          attachments,
        } = this.referral;
        const modifiedAttachments = attachments.map(attachment => ({
          ...attachment,
          response_requested: {
            request_types: attachment.referralRole === 'receiving' ? ['notes'] : [],
          },
        }));
        this.execute(action, () => this.actions.handleSendToProvider({ patient, patient_visit, organization: this.referral.hospital }, modifiedAttachments, {
          recipient: 'provider', parentReferral: this.referral, note, deadline: this.referral.discharge_date,
        }));
        break;
      }
      case 'decline_referral': {
        const [response] = params;
        this.execute(action, () => this.actions.openResponseModal({
          responseType: 'unavailable',
          referral: response.referral,
          referralResponse: response.referral.referralResponse,
          onConfirm: response.onConfirm,
        }));
        break;
      }
      default:
        this.unsupportedActionHandler(action);
        break;
    }
  }

  makeUndoable = action => () => {
    if (!UNDO_ENABLED) {
      return null;
    }
    switch (action) {
      case 'cancel':
        return null; // FIXME: What does this do? Is this possible???
      /** *********************
       * Sending
       ********************** */
      case 'send_to_providers':
        return { undo: 'skip_to_sending' };
      case 'extend_deadline':
        return { undo: 'skip_to_reviewing' };
      case 'reserve_provider':
        return { undo: 'skip_to_reserving' };
      case 'already_discharged':
      case 'skip_to_discharging':
      case 'discharge_now':
        return {
          undo: 'discharge_later',
          params: [{
            start_of_care: this.referral.start_of_care,
            dischargeDate: this.referral.discharge_date,
          }],
        };
      case 'discharge_later': {
        return {
          undo: 'change_discharge_date',
          params: [{
            start_of_care: this.referral.start_of_care,
            dischargeDate: this.referral.discharge_date,
          }],
        };
      }
      case 'not_discharged_yet':
        return { undo: 'discharge_now' };
      case 'skip_to_drafted': {
        const sent = this.referral.sent_referral_index
          .filter(sr => sr.confirmed && !sr.removed_at)
          .map(sr => sr.provider_id);
        const deadline = this.referral.time_window_closes_at;
        // FIXME: Implement action
        return {
          undo: 'resend_to_providers',
          params: [deadline, sent],
        };
      }
      case 'skip_to_sent':
        return { undo: 'skip_to_reviewing' };
      case 'skip_to_reviewing':
      case 'skip_to_reserving':
        return {
          undo: 'skip_to_sent',
          params: [this.referral.time_window_closes_at],
        };
      case 'reserve_provider_again':
        return { undo: 'skip_to_reserved' };
      case 'skip_to_reserved':
        return {
          undo: 'reserve_provider_again',
          params: [this.referral.provider.id],
        };
      /** *********************
       * Receiving
       ********************** */
      case 'patient_not_receiving_care':
        return null; // FIXME: Same as cancel above
      case 'accept':
      case 'decline':
        return { undo: 'change_response' };
      case 'skip_to_responded':
      case 'change_response': {
        let undo;
        if (this.referral.referralResponse.status === 'available') {
          undo = {
            undo: 'accept',
            params: [
              this.referral.referralResponse.id,
              { status: 'available' },
            ],
          };
        } else if (this.referral.referralResponse.status === 'unavailable') {
          undo = {
            undo: 'decline',
            params: [
              this.referral.referralResponse.declined_reason,
              this.referral.referralResponse.id,
            ],
          };
        }
        return undo;
      }
      case 'patient_arriving_later':
      case 'patient_receive_later':
      case 'accept_later': {
        return {
          undo: 'change_discharge_date',
          params: [{
            dischargeDate: this.referral.discharge_date,
          }],
        };
      }
      case 'patient_already_received_care':
        return {
          undo: 'change_discharge_date',
          params: [{
            dischargeDate: this.referral.discharge_date,
          }],
        };
      case 'patient_receive_now':
      case 'intake':
        return {
          undo: 'intake_not_complete',
          params: [{
            dischargeDate: this.referral.discharge_date,
          }],
        };
      case 'complete_care':
        return {
          undo: 'care_not_complete',
          params: [{
            dischargeDate: this.referral.discharge_date,
          }],
        };
      case 'intake_not_complete': {
        return {
          undo: 'intake',
          params: {
            start_of_care: this.referral.start_of_care,
            provider_anticipated_discharge_date: this.referral.provider_anticipated_discharge_date,
          },
        };
      }
      case 'early_discharge': {
        return {
          undo: 'change_provider_discharge_date',
          params: [{
            dischargeDate: this.referral.provider_actual_discharge_date,
          }],
        };
      }
      case 'still_in_care':
      case 'provider_discharge_later': {
        return {
          undo: 'change_provider_anticipated_discharge_date',
          params: [{
            dischargeDate: this.referral.provider_anticipated_discharge_date,
          }],
        };
      }
      case 'provider_already_discharged':
      case 'skip_to_provider_discharging':
      case 'provider_discharge_now':
        /*
         * FIXME: A lot needs to happen here. Mainly, the task needs to be updated
         * to be marked incomplete and the discharge data needs to be removed, meaning
         * the actual discharge date as well as discharge disposition, etc.
         */
        return null;

      default:
        return null;
    }
  }
}

export default ReferralActionEngine;
