/* eslint-disable no-nested-ternary */
import { PropertyFilter } from 'components/utils/AutocompleteField';

export const SELECTION_MODE_SEARCH = 'search';
export const SELECTION_MODE_ALL = 'all';

const getValuesForSelectionMode = (value, disableAllSelected) => ({
  selectionMode: disableAllSelected
    ? SELECTION_MODE_SEARCH
    : value === 'all'
      ? SELECTION_MODE_ALL
      : SELECTION_MODE_SEARCH,
  values: (value || '').split(',').filter(Boolean),
});

export const getInitialState = (value, disableAllSelected) => ({
  ...getValuesForSelectionMode(value, disableAllSelected),
  loading: false,
  inputValue: '',
  searchItems: [],
  version: 0,
});

export const reducer = (state, action) => {
  const { type } = action;

  const versioned = (input) => ({ ...input, version: state.version + 1 });

  switch (type) {
    case 'START_SEARCH_INPUT_VALUE': {
      const { inputValue } = action;
      return { ...state, inputValue, loading: true };
    }
    case 'CLEAR_VALUES': {
      return versioned({ ...state, values: [] });
    }
    case 'SET_SEARCH_ITEMS': {
      const { searchItems } = action;
      return {
        ...state,
        searchItems,
        loading: false,
      };
    }
    case 'STOP_LOADING': {
      return { ...state, loading: false };
    }
    case 'DESELECT_ITEM': {
      const { entry: { value } } = action;
      const nextValues = state.values.filter(val => val !== value);
      return versioned({ ...state, values: nextValues });
    }
    case 'SELECT_ITEM': {
      return versioned({ ...state, values: state.values.concat(action.entry.value) });
    }
    case 'SET_VALUES': {
      const { disableAllSelected, value } = action;
      return {
        ...state,
        ...getValuesForSelectionMode(value, disableAllSelected),
      };
    }
    case 'SET_SELECTION_MODE': {
      const { value } = action;
      const newState = { ...state, selectionMode: value };
      if (value === 'all') {
        newState.values = [];
        newState.inputValue = '';
        return versioned(newState);
      }
      return newState;
    }
    case 'RESET': {
      return getInitialState('');
    }
    default:
      return state;
  }
};

const filterItems = (items, value) => {
  const filter = PropertyFilter('label', 'value');
  return items.filter(item => filter(item, value));
};

export const makeHandleInputChange = (onInputChange, dispatch) => async (inputValue) => {
  dispatch({ type: 'START_SEARCH_INPUT_VALUE', inputValue });
  try {
    const searchItems = await onInputChange(inputValue);
    dispatch({ type: 'SET_SEARCH_ITEMS', searchItems: filterItems(searchItems, inputValue) });
  } catch (error) {
    // TODO: add error handling?
  } finally {
    dispatch({ type: 'STOP_LOADING' });
  }
};
