import styled from 'react-emotion';
import { css } from 'emotion';

import { Colors } from 'components/utils';

const {
  ERROR_LIGHT_FILL,
  MEDIUM_GRAY_FILL,
  WHITE,
} = Colors;

export const Icon = styled.div`
  margin-left: 3px;
  font-size: 24px;
  padding: 4px 4px;
  color: ${props => (props.unread ? ERROR_LIGHT_FILL : (props.color || WHITE))};
`;

export const InboxLinkDiv = styled.div`
  margin-top: 14px;
  font-family: 'futura-pt';
  font-size: 14px;
  font-weight: ${props => (props.unread ? 900 : 500)};
  text-decoration: none;
  color: ${props => (props.unread ? ERROR_LIGHT_FILL : MEDIUM_GRAY_FILL)};
  display: block;
`;

export const iconBorderStyle = css`
  background-color: #fff;
  cursor: pointer;
`;

export const inboxLinkContainerStyle = css`
  display: flex;
  padding: 0 .5rem;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const inboxReadLinkStyle = css`
  color: ${ERROR_LIGHT_FILL};
`;

export const initializingStyle = css`
  position: relative;
  opacity: 0.5;
`;

export const spinnerContainerStyle = css`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 4px;
  left: 0px;
  right: 0px;
`;
