/* eslint-disable camelcase, no-nested-ternary */
import { pick, uniqBy } from 'lodash';

import apiDirector from 'helpers/ApiDirector';
import ResponseError from 'helpers/ResponseError';
import { reportError } from 'helpers/ErrorHandler';
import { DateTime } from 'helpers/dates';
import { createTranslator } from 'helpers/i18n';
import { resetProviderSearch } from 'ducks/providerSearch';
import { openProviderSearchContactInfoModal, openUpdateReferralConflictModal } from 'ducks/ui';
import { createReferralObject } from 'models/Referral';
import { getReferralState } from 'components/home/DashboardUtils';
import GlobalModal, { modals } from 'helpers/GlobalModal';
import { filterExpiredInsurances } from 'helpers/referrals';

import * as actions from './actions';

const tr = createTranslator({
  attachments: {
    overview: {
      name: 'Aidin Patient Facesheet',
      help: 'Aidin generates this PDF from the info you\'ve included in your referral, like patient details, insurance, and care needs. You can print just this PDF, or print all attachments together in a single PDF',
    },
  },
  deidentified: {
    name: 'Deidentified Patient',
    patient_number: 'Deidentified',
  },
});

const mapAttachments = (attachments, attrs = {}) => (attachments ? attachments.map(a => ({
  ...a,
  name: a.name || a.full_name,
  dataSource: 'attachments',
  pendingState: a.code === 'Pending' ? 'fax' : undefined,
  dateAdded: a.created_at,
  meta: !a.meta ? a.meta : {
    ...a.meta,
    unreadable: a.file_content_type === 'application/pdf' ? a.meta.unreadable : 'invalid_content_type',
  },
  ...attrs,
  // TODO: protected? source?
})) : []);

const mapPatientReports = reports => (reports ? reports.map(r => ({
  dateAdded: r.updated_at,
  id: r.id,
  name: r.full_name,
  protected: false,
  dataSource: 'patient_reports',
  dataSourceId: r.web_service_report_id,
  url: `/api/patient_reports/${r.id}`,
})) : []);

const mapCareTypes = (values, lookups) => (values
  ? values.map(c => lookups.find(l => l.id === c.id)) : []).filter(i => i);

const getReservationStatus = (referral, referralRole, previewer) => {
  let reservationStatus = getReferralState(referralRole, referral);
  if (referralRole === 'receiving' && reservationStatus === 'missed.closed' && previewer) {
    reservationStatus = 'expired';
  }
  return reservationStatus;
};

/**
 * @returns Array of patient's insurances primarily sorted
 * by the primary flag and secondarily sorted alphabetically
 * by insurance_company_name.
 * @param {*} insurances array of patient insurances
 */
export const shapeInsurances = (insurances, patientVisit = {}) => {
  const sortedInsurances = [...insurances].sort((a, b) => {
    if (a.primary === b.primary) {
      if (a.secondary === b.secondary) {
        const companyA = (a.insurance_company_name || '').toLowerCase();
        const companyB = (b.insurance_company_name || '').toLowerCase();
        if (companyA === companyB) {
          return 0;
        }
        if (companyA > companyB) {
          return 1;
        }
        return -1;
      }
      return a.secondary ? -1 : 1;
    }
    return a.primary ? -1 : 1;
  });
  // Filter out insurances that have expired
  return filterExpiredInsurances(sortedInsurances, patientVisit);
};

const shapeUnit = unitFunc => value => (
  value && !Number.isNaN(Number(value)) ? unitFunc(value) : value
);
const shapeHeight = shapeUnit(h => `${h}" (${parseInt(h / 12, 10)}'${h % 12}")`);
const shapeWeight = shapeUnit(w => `${w}lb`);

export const assignReferralRole = (referral, referralRole, previewer = false, metadata = {}) => {
  const finalReferral = {
    ...referral,
    ...(referralRole ? referralRole.referral : null),
    currentRole: referralRole,
    ...metadata,
  };

  finalReferral.reservationStatus = getReservationStatus(finalReferral,
    finalReferral.referralRole, previewer);

  return createReferralObject(finalReferral);
};

export const shapeReferral = (referral, getState, options = {}) => {
  const {
    referral: possibleExistingReferral,
    session: {
      user: {
        previewer,
        organizations,
      },
    },
    system: {
      checklists = [],
    },
  } = getState();

  const existingReferral = possibleExistingReferral
    && possibleExistingReferral.id === referral.id
    && possibleExistingReferral;

  let finalReferral = {
    ...referral,
    patient: {
      ...referral.patient,
      hospital: referral.patient_visit
        ? referral.patient_visit.organization
        : referral.current_patient_visit
          ? referral.current_patient_visit.organization
          : referral.hospital,
      name: referral.patient ? (referral.patient.name || referral.patient.initials) : '',
      insurances: referral.patient ? shapeInsurances(referral.patient.insurances || [], referral.patient_visit || referral.current_patient_visit) : [],
      patient_number: referral.patient_visit
        ? referral.patient_visit.mrn
        : referral.patient
          ? referral.patient.patient_number
          : '',
      ...(referral.patient && referral.patient.deidentified ? tr('deidentified') : {}),
      height: referral.patient ? shapeHeight(referral.patient.height) : '',
      weight: referral.patient ? shapeWeight(referral.patient.weight) : '',
    },
    attachments: [],
    practice: referral.practice === 'disabled' ? null : referral.practice,
    activities: referral.activities || [],
    patient_visit: referral.patient_visit || referral.current_patient_visit || {},
    care_types: mapCareTypes(referral.care_types, getState().system.care_types),
    people: referral.people || [],
    followUp: (referral.follow_ups || [])[0],
    tasks: referral.tasks || [],
    checklist_configuration: checklists
      .find(({ id }) => id === referral.checklist_configuration_id) || {
      category: 'provider',
    },
  };
  if (referral.provider_search && referral.provider_search.zip_codes) {
    finalReferral.provider_search.zip_codes = uniqBy(referral.provider_search.zip_codes, 'zip');
  }
  if (finalReferral.checklist_configuration.category !== 'doc') {
    finalReferral.attachments.push({
      id: 'generated-overview',
      url: `/referrals/${referral.id}/pdfs/overview`,
      name: tr('attachments.overview.name'),
      help: tr('attachments.overview.help'),
      protected: true,
      generated: true,
    });
  }
  finalReferral.attachments = finalReferral.attachments
    .concat(mapAttachments(referral.attachments))
    .concat(mapAttachments(referral.provider_attachments, { referralRole: 'receiving' }))
    .concat(mapPatientReports(referral.patient_reports));

  let referralRoles = [];

  // Look for receiving organizations
  const receivers = organizations.filter(({ roles }) => roles.some(r => r === 'provider'))
    .map(({ organization }) => organization);
  if (receivers.length > 0) {
    const { sent_referrals, provider_sent_referrals } = finalReferral;
    const sentReferrals = (provider_sent_referrals || sent_referrals || []).map(sr => ({
      ...sr,
      tasks: sr.tasks || [],
    }));

    finalReferral.sent_referrals = sentReferrals;

    const onReferral = org => sentReferrals.some(sr => sr.provider_id === org.id);
    referralRoles = referralRoles.concat(
      receivers.filter(onReferral).map(o => {
        const referralResponse = sentReferrals.find(sr => sr.provider_id === o.id);
        return ({
          referral: {
            referralRole: 'receiving',
            referralRoleOrganization: o,
            referralResponse,
            reservationStatus: getReservationStatus({
              ...finalReferral,
              referralResponse,
            }, 'receiving', previewer),
            tasks: referralResponse.tasks,
          },
          organization: o,
        });
      }),
    );

    if (finalReferral.checklist_configuration.category === 'doc') {
      finalReferral.invitableProviders = [];
    } else {
      finalReferral.invitableProviders = receivers.filter(({ id, provider_type: type }) => (
        finalReferral.provider_type === type
        && !referralRoles.some(role => role.organization.id === id)
      ));
    }
  }

  // Look for sending organizations
  // FIXME: This doesn't feel quite right, but is a good start.
  const senders = organizations.filter(({ roles }) => roles.some(r => r === 'provider' || r === 'hospital'))
    .map(({ organization }) => organization);
  if (senders.length > 0) {
    const { provider_sent_referrals } = finalReferral;
    const sentReferrals = (provider_sent_referrals || []).map(sr => ({
      ...sr,
      tasks: sr.tasks || [],
    }));
    if (!finalReferral.sent_referrals) {
      finalReferral.sent_referrals = sentReferrals;
    }
    if (existingReferral && existingReferral.sent_referrals) {
      finalReferral.sent_referrals = finalReferral.sent_referrals || [];
      if (finalReferral.sent_referral_index) {
        finalReferral.sent_referrals = finalReferral.sent_referrals.concat(
          existingReferral.sent_referrals.filter(i => (
            !finalReferral.sent_referral_index
              .some(sr => sr.id === i.id && sr.updated_at === i.updated_at)
          )),
        );
      }
    }
    referralRoles = referralRoles.concat(
      senders.filter(({ id, organization_type }) => (
        (id === finalReferral.hospital.id
          && organization_type === finalReferral.hospital.organization_type)
        || (id === finalReferral.hospital_id && organization_type === finalReferral.hospital_type)
      )).map(o => ({
        referral: {
          referralRole: 'sending',
          referralRoleOrganization: o,
          reservationStatus: getReservationStatus(finalReferral, 'sending', previewer),
          tasks: finalReferral.tasks,
        },
        organization: o,
      })),
    );
  }

  // If this is a practice receiving referral, remove the hospital role
  if (finalReferral.practice === 'receiving') {
    referralRoles = referralRoles.filter(({ referral: rr }) => (
      rr.referralRole === 'receiving'
    ));
  }

  // Look for watcher organizations
  if (finalReferral.referral_watchers && finalReferral.referral_watchers.length > 0) {
    const watchers = finalReferral.referral_watchers.map((referralResponse) => ({
      referralResponse: {
        ...referralResponse,
        responseType: 'watching',
      },
      organization: organizations.find(uo => (
        uo.organization_id === referralResponse.organization_id
        && uo.organization_type === referralResponse.organization_type
      ))?.organization,
    })).filter(({ organization }) => !!organization);
    referralRoles = referralRoles.concat(watchers.map(({
      referralResponse,
      organization,
    }) => {
      const { referral_role: referralRole } = referralResponse;
      return {
        referral: {
          referralRole,
          referralRoleOrganization: organization,
          referralResponse,
          reservationStatus: getReservationStatus({
            ...finalReferral,
            referralResponse,
          }, referralRole, previewer),
          tasks: [],
        },
        organization,
      };
    }));
  }

  referralRoles = referralRoles.sort((a, b) => {
    const { referral: { referralRole: aRole } } = a;
    const { referral: { referralRole: bRole } } = b;
    if (aRole !== bRole) {
      if (aRole === 'sending') { return -1; }
      return 1;
    }
    if (aRole !== 'receiving') {
      // All other roles come before receiving without other preference
      return -1;
    }
    // Sort by response status
    const order = ['chosen', 'pending', 'under_review', 'available', 'unavailable'];
    const { referral: { referralResponse: { status: aStatus } = {} } } = a;
    const { referral: { referralResponse: { status: bStatus } = {} } } = b;
    return order.indexOf(aStatus) - order.indexOf(bStatus);
  });

  let referralRole = referralRoles[0] || null;
  if (options && options.selectedRole) {
    const {
      selectedRole: {
        organization: requestedOrganization, referralRole: requestedRole,
      },
    } = options;
    const updatedReferralRole = referralRoles.find(({
      organization: {
        id, organization_type,
      } = {},
      referral: {
        referralRole: role,
      } = {},
    }) => (
      id === requestedOrganization.id
      && organization_type === requestedOrganization.organization_type
      && role === requestedRole
    ));
    if (updatedReferralRole) {
      referralRole = updatedReferralRole;
    }
  } else if (existingReferral && existingReferral.currentRole) {
    // Keep the same referral role
    const {
      currentRole: {
        organization: eOrg,
        referral: {
          referralRole: eRole,
        } = {},
      } = {},
    } = existingReferral;
    const updatedReferralRole = referralRoles
      .filter(({ organization }) => organization && eOrg)
      .find(({
        organization: rOrg,
        referral: {
          referralRole: rRole,
        } = {},
      }) => (
        rRole === eRole
        && rOrg.id === eOrg.id
        && rOrg.organization_type === eOrg.organization_type
      ));
    if (updatedReferralRole) {
      referralRole = updatedReferralRole;
    }
  }

  finalReferral = assignReferralRole(finalReferral, referralRole, previewer, {
    referralRoles,
  });

  if (!finalReferral.reservationStatus && options && options.requireRole) {
    throw new ResponseError({ status: 404, message: 'missing_status' }, finalReferral);
  }

  return createReferralObject(finalReferral);
};

const handleNoReservationStatusError = (dispatch, referralId, rethrow) => (e) => {
  if (e.status === 404) {
    if (e.message === 'missing_status') {
      // TODO: Scrub and report to Rollbar.
      const referral = e.response;
      reportError(e, 'A referral was parsed without a reservationStatus', {
        status: referral.status,
        discharge_date: referral.discharge_date,
        currentRole: referral.currentRole,
        referralRole: referral.referralRole,
        hospital: referral.hospital,
        provider: referral.provider,
      });
    }

    /*
     * Here, we could send back more information based on the status, or from
     * the error message, as a prop on the "referral". For now, just give it a
     * null referral role which will display not found.
     */
    dispatch(actions.replaceReferral({
      id: referralId,
      referralRole: null,
      reservationStatus: '',
      hospital: e.response.hospital,
    }));
  } else if (rethrow) {
    throw e;
  }
};

export const createShapedReferral = referral => (dispatch, getState, requireRole = false) => (
  shapeReferral(referral, getState, { requireRole })
);


export const fetchReferral = (referralId, { source, pin, ...options } = {}) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${referralId}${source ? `?source=${source}` : ''}${source && pin ? `&pin=${pin}` : ''}`)
  .then(json => shapeReferral(json, getState, { requireRole: true, ...options }));

/* we probably don't need this call any more except to update a particular
   referral, e.g. after it's been updated */
// eslint-disable-next-line max-len
export const requestReferral = (referralId, { source, pin, ...options } = {}) => (dispatch) => dispatch(fetchReferral(referralId, { source, pin, ...options }))
  .then(json => dispatch(actions.replaceReferral(json)))
  .catch((e) => {
    if (e.status === 409 && e.message === 'pin_required') {
      GlobalModal.open(modals.TOGGLE_ENTER_PIN_MODAL, {
        notes: e.response.entity,
        onSubmit: (tryPin) => {
          GlobalModal.close(modals.TOGGLE_ENTER_PIN_MODAL);
          dispatch(requestReferral(referralId, { source, ...options, pin: tryPin }));
        },
      });
    } else {
      handleNoReservationStatusError(dispatch, referralId);
    }
  });

/* If ActionCable doesn't kick, manual update */
export const replaceReferralIfNecessary = (
  referralId,
  time,
  referralJson = null,
) => (dispatch, getState) => new Promise((resolve, reject) => {
  const doReplace = () => {
    if (referralJson) {
      Promise.resolve(referralJson)
        .then(json => shapeReferral(json, getState, { requireRole: true }))
        .then(json => dispatch(actions.replaceReferral(json)))
        .then(resolve, reject);
    } else {
      dispatch(requestReferral(referralId))
        .then(resolve, reject);
    }
  };
  setTimeout(() => {
    const { referral } = getState();
    if (!referral || !time || referral.id !== referralId) {
      doReplace();
    } else {
      const client = DateTime.parse(referral.updated_at);
      const server = DateTime.parse(time);
      if (server.isAfter(client)) {
        doReplace();
      } else {
        resolve();
      }
    }
  }, referralJson ? 0 : 2500);
});

export const refreshReferral = (referralId, time, referralJson = null) => dispatch => (
  dispatch(replaceReferralIfNecessary(referralId, time, referralJson))
);

export const requestConversations = referralId => dispatch => apiDirector.validateFetch(`/api/referrals/${referralId}/conversations`)
  .then(json => dispatch(actions.replaceConversations(json)));

export const requestReferralActivity = (referralId, { source = 'user', order = 'desc', limit } = {}) => dispatch => (
  apiDirector.validateFetch(`/api/referrals/${referralId}/activity?source=${source}&order=${order}${limit ? `&limit=${limit}` : ''}`)
    .then(json => (order === 'asc' ? json : dispatch(actions.replaceReferralActivity(json))))
);

export const requestReferralProviderOverview = referralId => () => apiDirector.validateFetch(`/api/referrals/${referralId}/provider-overview`);

export const requestPatientSearch = (query, practice = false, scope, organization) => dispatch => apiDirector.validateFetch('/api/search/patients', {
  method: 'POST',
  body: JSON.stringify({
    query,
    practice,
    scope,
    organization_id: organization ? organization.id : undefined,
    organization_type: organization
      ? (organization.organization_type || organization.type)
      : undefined,
  }),
})
  .then(results => dispatch(actions.replacePatients({ query, results })));

export const requestReferralPurchases = id => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/purchases`)
  .then(json => dispatch(actions.replaceReferralPurchases(json)));

export const purchaseReferralFeature = (id, data) => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/purchases`, {
  method: 'POST',
  body: JSON.stringify({
    organization_purchase: data,
  }),
})
  .then(() => dispatch(requestReferralPurchases(id)));

export const fetchSentReferrals = (id, query) => () => apiDirector.validateFetch(`/api/referrals/${id}/sent_referrals`, {
  method: 'POST',
  body: JSON.stringify({ query }),
});

export const requestSentReferrals = (
  id, query,
) => dispatch => dispatch(fetchSentReferrals(id, query))
  .then(json => dispatch(actions.replaceSentReferrals(json)));


const ALLOWED_SENT_REFERRAL_STATUS_OPTIONS = ['onConflict', 'referralRole', 'revert_to_initial'];

export const updateSentReferral = (id, data) => dispatch => apiDirector.validateFetch(`/api/sent_referrals/${id}`, {
  method: 'PATCH',
  body: JSON.stringify({
    sent_referral: data,
  }),
}).then(json => dispatch(actions.replaceSentReferral(json)));

export const updateSentReferralStatus = (
  id,
  data,
  organization = {},
  options = {},
) => dispatch => apiDirector.validateFetch(`/api/sent_referrals/${id}/status`, {
  method: 'POST',
  body: JSON.stringify({
    sent_referral: data,
    organization_id: organization.organization_id,
    organization_type: organization.organization_type,
    ...pick(options, ...ALLOWED_SENT_REFERRAL_STATUS_OPTIONS),
  }),
})
  .then(json => dispatch(actions.replaceSentReferral(json)));

export const batchUpdateSentReferralStatus = (responses, options = {}) => dispatch => apiDirector.validateFetch('/api/sent_referrals/batch', {
  method: 'POST',
  body: JSON.stringify({ ...pick(options, ...ALLOWED_SENT_REFERRAL_STATUS_OPTIONS), responses }),
})
  .then(json => dispatch(actions.replaceSentReferrals(json)));

export const createSentReferral = sentReferral => dispatch => apiDirector
  .validateFetch('/api/sent_referrals', {
    method: 'POST',
    body: JSON.stringify({ sent_referral: sentReferral }),
  })
  .then(json => dispatch(actions.replaceSentReferral(json)));

export const unreserveProvider = (referralId, responseId, data) => dispatch => apiDirector.validateFetch(`/api/sent_referrals/${responseId}/unreserve`, {
  method: 'POST',
  body: JSON.stringify({
    sent_referral: data,
  }),
})
  .then(() => dispatch(replaceReferralIfNecessary(referralId)));

export const renotifySentReferral = (id, mode, data, override) => () => apiDirector.validateFetch(`/api/sent_referrals/${id}/notify_by_${mode}`, {
  method: 'POST',
  body: JSON.stringify({
    notification_request: data,
    override,
  }),
});

export const checkForPayorPortalUpdates = (sentReferralId, sentReferralContactLogId) => () => apiDirector.validateFetch(`/api/sent_referrals/${sentReferralId}/check_for_payor_portal_updates?sent_referral_contact_log_id=${sentReferralContactLogId}`);

export const toggleAvailityAutomaticStatusUpdate = (sentReferralContactLogId, stopAutoUpdate) => () => apiDirector.validateFetch(`/api/sent_referral_contact_logs/${sentReferralContactLogId}/update_auto_status_update`, {
  method: 'POST',
  body: JSON.stringify({
    metadata: { stop_auto_status_update: stopAutoUpdate },
  }),
});

const createReferralUpdateConflictErrorHandler = (dispatch, resolve, reject) => (e) => {
  if (e.status === 409 && e.response.entity) {
    dispatch(openUpdateReferralConflictModal(e.response.entity, (conflictResolution = 'update_all') => {
      resolve(conflictResolution);
    }));
  } else {
    reject(e);
  }
};

// eslint-disable-next-line max-len
export const updateReferralStatus = (id, data, onConflict) => dispatch => new Promise((resolve, reject) => {
  apiDirector.validateFetch(`/api/referrals/${id}/status`, {
    method: 'POST',
    body: JSON.stringify({
      referral: data,
      onConflict,
    }),
  })
    .then(json => dispatch(replaceReferralIfNecessary(id, json.updated_at, json)))
    .then(resolve)
    .catch(createReferralUpdateConflictErrorHandler(dispatch, (conflictResolution) => {
      dispatch(updateReferralStatus(id, data, conflictResolution)).then(resolve);
    }, reject));
});

// eslint-disable-next-line max-len
export const updateReferral = (id, data, onConflict) => dispatch => new Promise((resolve, reject) => {
  if (Object.values(data).every(d => d === undefined)) {
    resolve();
    return;
  }
  apiDirector.validateFetch(`/api/referrals/${id}`, {
    method: 'PATCH',
    body: JSON.stringify({
      referral: data,
      onConflict,
    }),
  })
    .then(json => dispatch(replaceReferralIfNecessary(id, json.updated_at, json)))
    .then(resolve)
    .catch(createReferralUpdateConflictErrorHandler(dispatch, (conflictResolution) => {
      dispatch(updateReferral(id, data, conflictResolution)).then(resolve);
    }, reject));
});

export const updateReferralTask = (referralId, taskId, data, referral) => dispatch => apiDirector.validateFetch(`/api/referrals/${referralId}/tasks/${taskId}`, {
  method: 'PATCH',
  body: JSON.stringify({ task: data, referral }),
})
  .then(json => dispatch(replaceReferralIfNecessary(referralId, json.updated_at, json)));

export const removeAttachmentFromReferral = (id, organization, attachmentId, attachmentSource = 'attachments') => dispatch => apiDirector.validateFetch(`/api/${attachmentSource}/${attachmentId}?organization_id=${organization.organization_id || organization.id}&organization_type=${organization.organization_type}`, {
  method: 'DELETE',
})
  .then(json => dispatch(replaceReferralIfNecessary(id, json.updated_at)));

export const addAttachmentToReferral = (id, organization, files, target) => (dispatch) => {
  if (!files || files.length === 0) {
    return Promise.resolve(true);
  }

  const form = new FormData();
  files.forEach((file) => {
    form.append('files[]', file, file.name);
    form.append('form_ids[]', file.form_id || '');
  });
  form.append('attachable_id', target ? target.attachable_id : id);
  form.append('attachable_type', target ? target.attachable_type : 'Referral');
  form.append('organization_id', organization.organization_id || organization.id);
  form.append('organization_type', organization.organization_type);

  return apiDirector.validateFetch('/api/attachments', {
    method: 'POST',
    body: form,
  }, {
    Accept: 'application/json',
  })
    .then(json => {
      if (id) { dispatch(replaceReferralIfNecessary(id, json.updated_at)); }
    });
};

export const updateAttachmentOnReferral = (id, organization, attachment) => (dispatch) => {
  if (!attachment) {
    return Promise.resolve(true);
  }
  const form = new FormData();

  if (attachment.file) { form.append('file', attachment.file, attachment.name); }
  form.append('name', attachment.name);
  form.append('organization_id', organization.organization_id || organization.id);
  form.append('organization_type', organization.organization_type);

  return apiDirector.validateFetch(`/api/attachments/${attachment.id}`, {
    method: 'PATCH',
    body: form,
  }, {
    Accept: 'application/json',
  })
    .then(json => dispatch(replaceReferralIfNecessary(id, json.updated_at)));
};

export const createReferral = (patientId, options = {
  hospital_id: undefined,
  patient_visit_id: undefined,
  template: null,
  role: 'sending',
  mode: undefined,
  practice_for: undefined,
  disableReplaceOnCreate: false,
  provider_type: undefined,
  hospital_type: undefined,
  attachment_id: undefined,
  insurance_id: undefined,
  sent_referral_id: undefined,
  sent_referrals_attributes: undefined,
  referral_care_types_attributes: undefined,
  related_referral_id: undefined,
  start_of_care: null,
  end_of_care: null,
  discharge_date: null,
  drop_off_provider_id: undefined,
}) => (dispatch, getState) => apiDirector.validateFetch('/api/referrals', {
  method: 'POST',
  body: JSON.stringify({
    template: options.template,
    role: options.role,
    mode: options.mode,
    practice_for: options.practice_for ? {
      organization_id: options.practice_for.id,
      organization_type: options.practice_for.organization_type,
    } : undefined,
    attachment_id: options.attachment_id,
    insurance_id: options.insurance_id,
    related_referral_id: options.related_referral_id,
    sent_referral_id: options.sent_referral_id,
    referral: {
      hospital_id: options.patient_visit
        ? options.patient_visit.organization_id
        : options.hospital_id,
      hospital_type: options.patient_visit
        ? options.patient_visit.organization_type
        : options.hospital_type,
      patient_visit_id: options.patient_visit
        ? options.patient_visit.id
        : options.patient_visit_id,
      patient_id: patientId,
      provider_type: options.provider_type,
      sent_referrals_attributes: options.sent_referrals_attributes,
      referral_care_types_attributes: options.referral_care_types_attributes,
      start_of_care: options.start_of_care,
      end_of_care: options.end_of_care,
      discharge_date: options.discharge_date,
      drop_off_provider_id: options.drop_off_provider_id,
      coverage_category: options.coverage_category,
    },
  }),
})
  .then(json => shapeReferral(json, getState))
  .then((json) => {
    if (!options.disableReplaceOnCreate) {
      dispatch(actions.replaceReferral(json));
    }
    return json;
  });

export const createPatient = patient => () => apiDirector.validateFetch('/api/patients', {
  method: 'POST',
  body: JSON.stringify({ patient }),
});

export const requestFollowersForReferral = id => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/followers`)
  .then(json => dispatch(actions.replaceReferralFollowers(json)));

// eslint-disable-next-line no-unused-vars
export const addFollowerToReferral = (id, userId, organizationId, organizationType) => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/followers`, {
  method: 'POST',
  body: JSON.stringify({
    follower: {
      user: {
        id: userId,
      },
    },
    organization_id: organizationId,
    organization_type: organizationType,
  }),
})
  .then(json => dispatch(actions.replaceReferralFollowers(json)));

// eslint-disable-next-line no-unused-vars
export const removeFollowerFromReferral = (id, userId, organizationId, organizationType) => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/followers/${userId}?organization_id=${organizationId}&organization_type=${organizationType}`, {
  method: 'DELETE',
})
  .then(json => dispatch(actions.replaceReferralFollowers(json)));

export const removeOtherFollowersFromReferral = (id, _userId, organizationId, organizationType) => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/other_followers?organization_id=${organizationId}&organization_type=${organizationType}`, {
  method: 'DELETE',
})
  .then(json => dispatch(actions.replaceReferralFollowers(json)));

export const requestWatchersForReferral = id => dispatch => apiDirector.validateFetch(`/api/referrals/${id}/watchers`)
  .then(json => dispatch(actions.replaceReferralWatchers(json)));

export const refreshWatchersForReferral = id => dispatch => dispatch(requestWatchersForReferral(id))
  .then(() => dispatch(requestFollowersForReferral(id)));

// eslint-disable-next-line no-unused-vars
export const addWatcherToReferral = (referral, organizationId, organizationType) => (dispatch, getState) => apiDirector.validateFetch(`/api/referrals/${referral.id}/watchers`, {
  method: 'POST',
  body: JSON.stringify({
    referral_watcher: {
      organization_id: organizationId,
      organization_type: organizationType,
      inviting_organization_id: referral.hospital_id,
      inviting_organization_type: referral.hospital_type || 'Hospital',
      inviting_user_id: getState().session.user.id,
      referral_role: 'sending',
    },
  }),
});

// eslint-disable-next-line no-unused-vars
export const removeWatcherFromReferral = (referral, id) => dispatch => apiDirector.validateFetch(`/api/referrals/${referral.id}/watchers/${id}`, {
  method: 'DELETE',
});

export const sendReferralToProviders = (
  referralId,
  providerIds,
  suggestedDeadline,
  practice,
  options = {},
) => (dispatch, getState) => {
  const opts = {
    skipContactInfoCheck: false,
    ...options,
  };
  const { payorPortalResponseDetails } = opts;
  const sentMap = (getState().referral.sent_referral_index || [])
    .filter(sr => providerIds.some(id => id === sr.provider_id))
    .reduce((set, { id, provider_id } = {}) => ({ ...set, [provider_id]: id }), {});
  return apiDirector.validateFetch(`/api/referrals/${referralId}/providers`, {
    method: 'PUT',
    body: JSON.stringify({
      suggested_deadline: suggestedDeadline,
      skip_contact_info_check: opts.skipContactInfoCheck ? true : undefined,
      referral: {
        ...(payorPortalResponseDetails ? { payorPortalResponseDetails } : {}),
        practice,
        sent_referrals_attributes: providerIds.map(id => ({
          id: sentMap[id] || undefined,
          provider_id: id,
          confirmed: true,
          removed_at: null,
          status: 'pending',
        })),
      },
    }),
  })
    .then(json => dispatch(replaceReferralIfNecessary(referralId, json.updated_at, json)))
    .then(() => dispatch(resetProviderSearch()))
    .then(opts.onSuccess)
    .catch((e) => {
      if (e.message === 'ContactInfo') {
        const conflictingProviders = e.response.entity;
        const safeProviders = providerIds
          .filter(id => !conflictingProviders.some(({ id: cId }) => id === cId));
        dispatch(openProviderSearchContactInfoModal({
          providers: conflictingProviders,
          providerCount: providerIds.length,
          onSubmit: updatedProviders => dispatch(sendReferralToProviders(
            referralId,
            safeProviders.concat(updatedProviders),
            suggestedDeadline,
            practice,
            {
              ...opts,
              skipContactInfoCheck: true,
            },
          )),
          onCancel: opts.onCancel,
        }));
      } else if (e.message === 'PayorPortal') {
        const {
          requirements,
          payorPortalService,
          payorPortalService: {
            id: payorPortalServiceId,
          },
          provider_search_result_contact_detail: details,
          provider,
        } = e.response.entity;
        GlobalModal.open(modals.TOGGLE_PROVIDER_CONFLICT_MODAL, {
          requirements,
          payorPortalService,
          provider,
          providerContactDetails: details,
          onSubmit: data => dispatch(sendReferralToProviders(
            referralId,
            providerIds,
            suggestedDeadline,
            practice,
            {
              ...opts,
              payorPortalResponseDetails: {
                ...data.payorJSONResponse,
                payorPortalServiceId,
                providerId: data.id,
              },
            },
          )),
          onClose: () => GlobalModal.close(modals.TOGGLE_PROVIDER_CONFLICT_MODAL),
          onCancel: opts.onCancel,
        });
      } else {
        throw e;
      }
    });
};

export const createProvider = (requestor, form) => () => apiDirector.validateFetch('/api/providers', {
  method: 'POST',
  body: JSON.stringify({
    requestor,
    provider: form,
  }),
});

export const refreshPatientReport = (referralId, reportId) => (dispatch, getState) => apiDirector.validateFetch(`/api/patient_reports/${reportId}/refresh`)
  .then((json) => {
    if (getState().referral && getState().referral.id === referralId) {
      dispatch(actions.updateReferralAttachmentDate(referralId, reportId, json.updated_at));
    }
    return json;
  });

export const requestEpicDocuments = referralId => () => apiDirector.validateFetch(`/api/referrals/${referralId}/documents`);

export const attachEpicDocuments = (referralId, documents) => (dispatch) => {
  const groups = documents.reduce((sum, document) => {
    const [group, id] = document.split('/');
    if (!sum[group]) { sum[group] = []; } // eslint-disable-line
    sum[group].push(id);
    return sum;
  }, {});
  return apiDirector.validateFetch(`/api/referrals/${referralId}/documents`, {
    method: 'PATCH',
    body: JSON.stringify({
      referral: {
        attachable_attachments_attributes: groups.attachments ? groups.attachments.map(id => ({
          referral_id: referralId,
          attachment_id: id,
        })) : undefined,
        // eslint-disable-next-line
        patient_reports_attributes: groups.web_service_reports ? groups.web_service_reports.map(id => ({
          referral_id: referralId,
          web_service_report_id: id,
          generate_report: true,
        })) : undefined,
      },
    }),
  })
    .then(json => dispatch(replaceReferralIfNecessary(referralId, json.updated_at)));
};

export const sendDocument = ({
  attachmentId, provider, saveProvider, referralId,
}) => () => apiDirector.validateFetch(`/api/attachments/${attachmentId}/send`, {
  method: 'POST',
  body: JSON.stringify({
    referral_id: referralId,
    id: attachmentId,
    recipient: {
      to_name: provider.name,
      to_number: provider.fax,
    },
    provider,
    saveProvider,
  }),
});

export const sendToEpicPreFlight = (id, options, override) => () => apiDirector.validateFetch(`/api/patient_visits/${id}/communication_records`, {
  method: 'POST',
  body: JSON.stringify({ communication_records: options, override }),
});

export const sendToEpic = (id, options) => () => apiDirector.validateFetch(`/api/referrals/${id}/epic_records`, {
  method: 'POST',
  body: JSON.stringify({ options }),
});

export const reopenAuthReferral = (referralId, timeWindowClosesAt) => () => apiDirector.validateFetch(`/api/checklists/${referralId}`, {
  body: JSON.stringify({
    transaction: {
      tasks: [
        {
          complete: true,
          payload: {
            referral: {
              time_window_closes_at: timeWindowClosesAt,
            },
          },
          task_type: 'reopen',
        },
      ],
    },
  }),
  method: 'PATCH',
});

export const updateContactLogStatus = (id, status, metadata) => () => apiDirector.validateFetch(`/api/sent_referral_contact_logs/${id}`, {
  method: 'PATCH',
  body: JSON.stringify({
    sent_referral_contact_log: {
      status,
      metadata,
    },
  }),
});
