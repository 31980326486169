/*
 * Color palette, so we can map these to names for consistency.
 * Extracted from the style guide
 */

const ThemeControl = {
  v1: {
    colors: {
      BODY_FILL: '#f9f9fa',
      DARKER_GRAY_FILL: '#666666',
      GRAY_FILL: '#aaaaaa',
      SECONDARY_FILL: '#777777',
      MEDIUM_DARK_GRAY_FILL: '#848484',
      MEDIUM_LIGHT_GRAY_FILL: '#dddddd',
      MEDIUM_GRAY_FILL: '#cccccc',
      LIGHT_GRAY_FILL: '#e6e6e6',
      LIGHTEST_GRAY_FILL: '#f9f9f9',

      SENDING_PRIMARY_BUTTON_FILL: '#ebb300',
      RECEIVING_PRIMARY_BUTTON_FILL: '#8070ff',

      // Sending
      SENDING_DARKER_FILL: '#ebb300',
      SENDING_PROGRESS_FILL: '#a07600',
      SENDING_LIGHTEST_FILL: '#fff5d7',
      SENDING_BADGE_FILL: '#FBD14B',

      // Receiving
      RECEIVING_DARKEST_FILL: '#4A39CF',
      RECEIVING_DARKER_FILL: '#8070ff',
      RECEIVING_PROGRESS_FILL: '#4a39cf',
      RECEIVING_LIGHTEST_FILL: '#e5e1ff',
      RECEIVING_LIGHT_FILL: '#bcb4fa',
      RECEIVING_BADGE_FILL: '#bcb4fa',

      // Confirmation
      COMPLETED_FILL: '#eeeeee',
      CONFIRMATION_FILL: '#57b56c',
      CONFIRMATION_TRANSPARENT_FILL: 'rgba(87, 181, 108, 0.1)',

      // Messages
      MESSAGE_FILL: 'rgba(67,121,255,0.1)',

      // Notifications
      NOTIFICATION_TRANSPARENT_FILL: 'rgba(67, 121, 255, 0.1)',
      NOTIFICATION_FILL: '#4379ff',
      PRACTICE_FILL: '#95ADF5',

      // Errors and other Flags
      POOR_QUALITY_FILL: '#BED57B',
      ERROR_DARKER_FILL: '#EE070D',
      ERROR_LIGHT_FILL: '#ff7b7e',
      ERROR_LIGHTEST_FILL: '#ffd6d6',
      URGENT_OVERDUE: '#ff3e43',
      WARNING_LIGHTEST_FILL: '#FFE0E1',
      WARNING_PROGRESS_FILL: '#AE4F51',
      WARNING_TRIANGLE_FILL: '#ff7b7e',

      // Text
      UPDATED: '#fbd14b',
      WHITE: '#ffffff',
      BLACK: '#333333',
      GRAY: '#999999',
      TEXT_ERROR: '#fe5054',

      // Hovers
      TRANSPARENT: 'transparent',
      UNREAD: 'rgba(67,121,255,0.1)',
      UNREAD_HOVER: 'rgba(67,121,255,0.16)',
      ZIP_CODE_ELIGIBLE: '#8AACFE',

      // Other Components
      TIP_ERROR: '#ffd6d6',
      TIP_WARNING: '#fff5d7',
      TIP_DEFAULT: '#f3f3f3',
      TAG_DEFAULT: '#e9e9e9',
      CONTENT_PANEL_TITLE: '#dddddd',
      SELECT_FIELD_SELECTED: '#61cee1',

      TEXTFIELD_BORDER_COLOR: 'transparent',

      CHECKBOX_CHECKED: '#57b56c',
      CHECKBOX_DISABLED: '#dddddd',
      BORDER_COLOR: '#dfdfe2',
    },
    fonts: {
      BODY_TEXT: 'Cabin',
      LABEL_TEXT: "'futura-pt'",
      INFO_TEXT: '"pt-serif"',
    },
    styles: {},
  },
  v2: {
    colors: {
      BODY_FILL: '#ffffff',
      DARKER_GRAY_FILL: '#5e5f6c',
      GRAY_FILL: '#7d7e8b',
      SECONDARY_FILL: '#c7c7cb',
      MEDIUM_DARK_GRAY_FILL: '#f2f2f3',
      MEDIUM_LIGHT_GRAY_FILL: '#f8f8f9',
      MEDIUM_GRAY_FILL: 'inherit',
      LIGHT_GRAY_FILL: 'inherit',
      LIGHTEST_GRAY_FILL: 'inherit',

      SENDING_PRIMARY_BUTTON_FILL: '#fc2e5c',
      RECEIVING_PRIMARY_BUTTON_FILL: '#fc2e5c',

      // Sending
      SENDING_DARKER_FILL: '#fc2e5c',
      SENDING_PROGRESS_FILL: 'inherit',
      SENDING_LIGHTEST_FILL: 'inherit',
      SENDING_BADGE_FILL: '#609ff3',
      DASHBOARD_PRIMARY: '#fc2e5c',
      DASHBOARD_ACTION: '#FD9EA4',

      // Receiving
      RECEIVING_DARKEST_FILL: '#3c4277',
      RECEIVING_DARKER_FILL: '#3c4277', // -----------------------> DONT FORGET ABOUT ME CHANGE BACK TO INHERIT, BUT IT SEEMS  LIKE THIS COLOR IS BEING USED THE MOST IN THE APPLICATION
      RECEIVING_PROGRESS_FILL: 'inherit', // ------------------> this color is fine. ^ TO CONTINUE (DARKEST_COLOR Occ.: 11 vs DARKER_COLOR Occ.: 127)
      RECEIVING_LIGHTEST_FILL: 'inherit',
      RECEIVING_LIGHT_FILL: 'inherit',
      RECEIVING_BADGE_FILL: '#9da2dd',

      // Confirmation
      COMPLETED_FILL: 'inherit',
      CONFIRMATION_FILL: 'inherit',
      CONFIRMATION_TRANSPARENT_FILL: 'inherit',

      // Messages
      MESSAGE_FILL: '#CFD0EB',
      MESSAGE_BORDER_UNSELECTED: '#dfdfe2',
      MESSAGE_BORDER_SELECTED: '#3c4277',

      // Notifications
      NOTIFICATION_TRANSPARENT_FILL: 'inherit',
      NOTIFICATION_FILL: '#dfdfe2',
      PRACTICE_FILL: '#dfdfe2',

      // Errors and other Flags
      POOR_QUALITY_FILL: 'inherit',
      ERROR_DARKER_FILL: '#fc2e5c',
      ERROR_LIGHT_FILL: '#ff7b7e',
      ERROR_LIGHTEST_FILL: 'inherit',
      URGENT_OVERDUE: '#fd9ea4',
      WARNING_LIGHTEST_FILL: 'inherit',
      WARNING_PROGRESS_FILL: 'inherit',
      WARNING_TRIANGLE_FILL: '#fc2e5c',

      // Text
      UPDATED: 'inherit',
      WHITE: '#ffffff',
      BLACK: '#212338',
      TEXT_ERROR: '#fc2e5c',

      // Hovers
      TRANSPARENT: 'transparent',
      UNREAD: '#dfdfe2',
      UNREAD_HOVER: '#212338',
      ZIP_CODE_ELIGIBLE: 'inherit',

      // Other Components
      TIP_ERROR: 'inherit',
      TIP_WARNING: 'inherit',
      TIP_DEFAULT: '#dfdfe2',
      TAG_DEFAULT: '#dfdfe2',
      CONTENT_PANEL_TITLE: 'inherit',
      SELECT_FIELD_BORDER: '#dfdfe2',
      SELECT_FIELD_SELECTED: '#9da2dd',

      TEXTFIELD_BORDER_COLOR: '#dfdfe2',

      // Status / Task Label Colors
      DASHBOARD_LABEL_OVERDUE: '#fd9ea4',
      DASHBOARD_LABEL_LATE: '#fd9ea4',
      DASHBOARD_LABEL_EXPIRED: '#dddddd',
      DASHBOARD_LABEL_PRACTICE: '#dfdfe2',
      DASHBOARD_LABEL_NEW: '#f1a762',

      DASHBOARD_LABEL_SENDING_DRAFTED: '#babbcc',
      DASHBOARD_LABEL_SENDING_SENDING: '#f1a762',
      DASHBOARD_LABEL_SENDING_SENT: '#9da2dd',
      DASHBOARD_LABEL_SENDING_REVIEWING_NONE: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_REVIEWING_ALL: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_REVIEWING_EXPIRED: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_REVIEWING_RESPONSE_REVIEW: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_RESERVING_PENDING: '#79c07b',
      DASHBOARD_LABEL_SENDING_RESERVING_COMPLETE: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_RESERVING_INHOUSE: '#609ff3',
      DASHBOARD_LABEL_SENDING_RESERVED_INHOUSE: '#79c07b',
      DASHBOARD_LABEL_SENDING_RESERVED_DISCHARGED: '#79c07b',
      DASHBOARD_LABEL_SENDING_RESERVED_AUTHORIZED: '#79c07b',
      DASHBOARD_LABEL_SENDING_DISCHARGING: '#79c07b',
      DASHBOARD_LABEL_SENDING_DISCHARGED: '#79c07b',
      DASHBOARD_LABEL_SENDING_UPDATE_RECEIVIED: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_UPDATE_PENDING: '#fd9ea4',
      DASHBOARD_LABEL_SENDING_COMPLETED: '#79c07b',
      DASHBOARD_LABEL_SENDING_CANCELLED: '#dfdfe2',

      DASHBOARD_LABEL_RECEIVING_RESPONDING_VIEWED: '#fd9ea4',
      DASHBOARD_LABEL_RECEIVING_RESPONDING_UNDER_REVIEW: '#fd9ea4',
      DASHBOARD_LABEL_RECEIVING_RESPONDED_ACCEPTED_OPEN: '#9da2dd',
      DASHBOARD_LABEL_RECEIVING_RESPONDED_ACCEPTED_CLOSED: '#9da2dd',
      DASHBOARD_LABEL_RECEIVING_RESPONDED_DECLINED_OPEN: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_RESPONDED_DECLINED_CLOSED: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_MISSED_OPEN: '#fd9ea4',
      DASHBOARD_LABEL_RECEIVING_MISSED_CLOSED: '#fd9ea4',
      DASHBOARD_LABEL_RECEIVING_REMOVED: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_CANCELLED: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_REJECTED_OPEN: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_REJECTED_CLOSED: '#dfdfe2',
      DASHBOARD_LABEL_RECEIVING_ACCEPTED: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_INCOMING_INTAKE: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_INCOMING_TRANSACTIONAL: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_DISCHARGING: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_DISCHARGED_TRANSACTIONAL: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_DISCHARGED_OTHER: '#79c07b',
      DASHBOARD_LABEL_RECEIVING_INCARE: '#79c07b',

      DASHBOARD_LABEL_CANCELLED_TEXT_COLOR: '#5e5f6c',

      DASHBOARD_ROW_BORDER: '#dfdfe2',
      DASHBOARD_REFERRAL_HOVER: '#f8f8f9',

      BORDER_COLOR: '#dfdfe2',
      CHECKBOX_INDETERMINATE: '#ebb300',
      CHECKBOX_CHECKED: '#79c07b',
      CHECKBOX_UNCHECKED_BORDER: '#e5e5e8',
      CHECKBOX_DISABLED: '#dddddd',

      MODAL_DARK_BACKGROUND: '#f2f2f3',
      PROVIDER_SEARCH_CONFIRMATION_FILL: '#79C07B',
    },
    fonts: {
      BODY_TEXT: 'Roboto',
      LABEL_TEXT: 'Roboto',
      INFO_TEXT: 'Roboto',
    },
    styles: {},
  },
};

export default ThemeControl;
