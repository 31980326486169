import styled from 'react-emotion';

import * as colors from 'components/utils/Colors';

const {
  LIGHTEST_GRAY_FILL: HoverColor,
  COMPLETED_FILL: SelectedColor,
} = colors;

/**
 * FIXME: The colors in ThemeControl are wrong, so I can't
 * pull them in as colors. Using Colors directly for now.
 */
export const ListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => (props.selected ? SelectedColor : 'inherit')};
  padding: 7px 0px 6px;
  width: 100%;
  &:hover {
    background-color: ${HoverColor};
  }
`;

export const ListItemContent = styled.div`
  padding: 0px 5px;
`;

export const SubTitle = styled.div();
