import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { groupBy, isEqual } from 'lodash';

import { createTranslator } from 'helpers/i18n';
import { applyTestIdentifier } from 'helpers/development';
import usePrevious from 'hooks/usePrevious';

import RadioField from 'components/utils/RadioField';
import Divider from 'components/utils/Divider';
import Panel from 'components/utils/Panel';
import Button from 'components/utils/Button';
import CheckboxField from 'components/utils/CheckboxField';
import TextField from 'components/utils/TextField';
import LoadingPanel from 'components/utils/LoadingPanel';

import { Secondary } from 'components/utils/Styles';

import {
  SELECTION_MODE_SEARCH,
  SELECTION_MODE_ALL,
  reducer,
  getInitialState,
  makeHandleInputChange,
} from './functions';

import {
  SubTitle,
  ListItem,
  ListItemContent,
} from './styles';

import text from './translations';

const tr = createTranslator(text);

const renderEntry = (state, dispatch, entry, {
  grouped,
} = {}) => {
  const { value, label } = entry;
  const isSelected = state.values.some(val => val === value);
  return (
    <ListItem
      key={value}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isSelected) {
          dispatch({ type: 'DESELECT_ITEM', entry });
        } else {
          dispatch({ type: 'SELECT_ITEM', entry });
        }
      }}
    >
      <ListItemContent>
        <CheckboxField value={isSelected} label={label} />
      </ListItemContent>
      <ListItemContent>
        <Secondary>
          {grouped && entry.group.toUpperCase()}
        </Secondary>
      </ListItemContent>
    </ListItem>
  );
};

const renderGroup = (state, dispatch, { group, entries }) => entries.length > 0 && (
  <div key={group}>
    <SubTitle>{group}</SubTitle>
    {entries.map(entry => renderEntry(state, dispatch, entry, {
      grouped: true,
    }))}
  </div>
);

const renderValues = (state, dispatch, { grouped, valueRenderer }) => {
  const { values } = state;
  const savedValues = values.map(valueRenderer).filter(Boolean);
  if (grouped) {
    return (
      <Panel spacing={10}>
        {Object.entries(groupBy(savedValues, 'group'))
          .map(([group, entries]) => renderGroup(state, dispatch, { group, entries }))}
      </Panel>
    );
  }
  return savedValues.map(entry => renderEntry(state, dispatch, entry));
};

const renderSearchOptions = (state, dispatch) => {
  const { loading, searchItems } = state;
  return (loading
    ? (
      <LoadingPanel height="200px" />
    ) : (
      searchItems.map(entry => (renderEntry(state, dispatch, entry)))
    )
  );
};

/**
 * Provides an interface to search for items that need to be
 * loaded from the server, such as a list of users or providers.
 * The caller is expected to provide labels and text.
 */
const SearchableMultiSelectField = ({
  disableAllSelected,
  grouped,
  help,
  label,
  labelElement,
  onInputChange,
  onSelect,
  placeholder,
  selectionModeLabels = {},
  value,
  valueRenderer,
}) => {
  const previousValue = usePrevious(value);
  const [state, dispatch] = useReducer(reducer, getInitialState(value, disableAllSelected));
  const { selectionMode } = state;

  // Reset when this component is unmounted
  useEffect(() => () => { dispatch({ type: 'RESET' }); }, []);

  // Push changes up to parent when values changes
  useEffect(() => {
    if (state.version > 0) {
      onSelect(state.values);
    }
  }, [state.version]);

  useEffect(() => {
    if (!isEqual(previousValue, value)) {
      dispatch({
        type: 'SET_VALUES',
        disableAllSelected,
        value,
      });
    }
  }, [previousValue, value]);

  const renderSearchAndSelect = () => (
    <Panel spacing={10}>
      <div {...applyTestIdentifier('searchable-multiselect') /* eslint-disable-line react/jsx-props-no-spreading, max-len */}>
        <TextField
          autoFocus
          value={state.inputValue}
          onChange={makeHandleInputChange(onInputChange, dispatch)}
          labelElement={labelElement}
          label={label}
          placeholder={placeholder}
          help={help}
        />
        {renderSearchOptions(state, dispatch)}
      </div>
      {state.values.length > 0 && (
        <Panel spacing={0}>
          <Divider margin={10} />
          <Button
            small
            link
            disabled={state.loading}
            onClick={() => dispatch({ type: 'CLEAR_VALUES' })}
          >
            {tr('clearAll')}
          </Button>
        </Panel>
      )}
      <div>
        {renderValues(state, dispatch, { grouped, valueRenderer })}
      </div>
    </Panel>
  );

  if (disableAllSelected) {
    return renderSearchAndSelect();
  }

  return (
    <Panel spacing={0}>
      <RadioField
        items={[
          SELECTION_MODE_ALL,
          SELECTION_MODE_SEARCH,
        ].map(item => ({
          label: selectionModeLabels[item],
          value: item,
        }))}
        contentRenderer={(item) => {
          if (item.value === selectionMode && selectionMode !== SELECTION_MODE_ALL) {
            return renderSearchAndSelect();
          }
          return null;
        }}
        onSelect={(item) => {
          dispatch({
            type: 'SET_SELECTION_MODE',
            value: item.value,
          });
        }}
        value={selectionMode}
      />
    </Panel>
  );
};

SearchableMultiSelectField.propTypes = {
  /** True to require user to make selections, false to allow for "all" */
  disableAllSelected: PropTypes.bool,
  /** Render items grouped by optional group key */
  grouped: PropTypes.bool,
  /** From `Field`; optional help text shown below the filter */
  help: PropTypes.string,
  /** From `Field`; text shown as the filter label */
  label: PropTypes.string.isRequired,
  /** From `Field`; optional element to use for the label */
  labelElement: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  /** Async callback. Will be handed the searchTerm to get new items */
  onInputChange: PropTypes.func.isRequired,
  /**
   * Callback when an item is selected; will return an Array of
   * strings representing the values chosen. It will not contain
   * any labels (that is on the caller to manage).
   */
  onSelect: PropTypes.func.isRequired,
  /** From `Field`; placeholder text shown in the filter */
  placeholder: PropTypes.string,
  /**
   * When "all" is supported (meaning: "select all possible options"),
   * a RadioField will be shown asking the user if they want to choose
   * "all" or make selections. This is important because choosing
   * "all (possible options)" is different than choosing
   * "everything that is visible." And most users typically want to
   * choose "all" when making this selection. This simplifies
   * the UI in this case. The object supplied here should use keys
   * for "all" and "search" that will then be used to render the
   * label of the RadioField item.
   */
  selectionModeLabels: PropTypes.shape({
    [SELECTION_MODE_ALL]: PropTypes.string,
    [SELECTION_MODE_SEARCH]: PropTypes.string,
  }),
  /** The selected values that should be checked as a CSV */
  value: PropTypes.string,
  /**
   * Function used to display selected items in trigger.
   * Will receive a string key for each item. It is expected
   * to return an object containing the key, label, and group,
   * if the `grouped` option is set to true.
   */
  valueRenderer: PropTypes.func.isRequired,
};

SearchableMultiSelectField.defaultProps = {
  disableAllSelected: undefined,
  labelElement: undefined,
  grouped: false,
  help: undefined,
  value: '',
  placeholder: undefined,
  selectionModeLabels: undefined,
};

export default SearchableMultiSelectField;
