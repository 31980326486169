/* eslint-disable no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';

import AidinPropTypes from 'helpers/AidinPropTypes';
import { DateTime } from 'helpers/dates';
import { createTranslator } from 'helpers/i18n';
import Session from 'models/Session';

import Panel from 'components/utils/Panel';
import Popup from 'components/utils/Popup';
import * as Styles from 'components/utils/Styles';

const { PopupContent, Secondary } = Styles;

const tr = createTranslator({
  more: count => `+ ${count} more`,
  mrn: (value, prefix) => [prefix, value].filter(i => !!i).join(' '),
  prefix: 'MRN:',
  empty: 'No MRN available',
  prefix_empty: (prefix) => `${prefix} N/A`,
});

/**
 * Display the Patient number, and any additional MRNs tied to this
 * patient. Later, can lazy-load details like organization names &
 * other patient visit details.
 */
const PatientNumber = ({
  className,
  element,
  patient: {
    patient_number: assignedMRN,
    mrns,
  } = {},
  prefix,
  compact,
  session: {
    organizations = [],
  } = {},
}) => {
  const renderElement = (mrn, fallbackKey) => {
    const ContainerElement = element || 'div';
    const itemKey = mrn || `mrn-${fallbackKey}`;
    const itemPrefix = prefix
      ? typeof prefix === 'string'
        ? prefix
        : tr('prefix')
      : undefined;

    let itemText;
    if (mrn) {
      itemText = tr('mrn', mrn, itemPrefix);
    } else if (compact) {
      itemText = tr('prefix_empty', itemPrefix);
    } else {
      itemText = tr('empty');
    }
    return (
      <ContainerElement key={itemKey} className={className}>
        {itemText}
      </ContainerElement>
    );
  };

  if (assignedMRN) {
    return renderElement(assignedMRN);
  }

  const items = uniqBy((mrns || []).filter(({ organization_id: id, organization_type: type }) => (
    organizations.some(org => org.id === id && org.organization_type === type)
  )).sort((a, b) => {
    const { updated_at: aDate, mrn: aName = '' } = a;
    const { updated_at: bDate, mrn: bName = '' } = b;
    if (aDate === bDate) { return 0; }
    if (!aDate) { return -1; }
    if (!bDate) { return 1; }
    let sort = DateTime.parse(bDate).toDate().getTime() - DateTime.parse(aDate).toDate().getTime();
    if (sort === 0) {
      if (!aName) { return 1; }
      if (!bName) { return -1; }
      sort = aName.localeCompare(bName);
    }
    return sort;
  }), 'mrn');

  if (items.length === 0) { return null; }

  const [{ mrn: currentMRN } = {}, ...otherMRNs] = items;

  return (
    <Panel spacing={4} orientation="horizontal" align="center">
      {renderElement(currentMRN)}
      {otherMRNs.length > 0 && (
        <Popup
          trigger={<Secondary>{tr('more', otherMRNs.length)}</Secondary>}
          triggerOn="hover"
          width={300}
        >
          <PopupContent>
            <Panel spacing={6}>
              {otherMRNs.map(({ mrn }, idx) => renderElement(mrn, idx))}
            </Panel>
          </PopupContent>
        </Popup>
      )}
    </Panel>
  );
};
PatientNumber.propTypes = {
  className: PropTypes.string,
  element: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
  patient: PropTypes.shape({
    patient_number: PropTypes.string,
    mrns: PropTypes.arrayOf(PropTypes.shape({
      organization_id: PropTypes.number,
      organization_type: PropTypes.string,
      mrn: PropTypes.string,
      updated_at: AidinPropTypes.dateTime,
    })),
  }).isRequired,
  prefix: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  compact: PropTypes.bool,
  session: Session.isRequired,
};
PatientNumber.defaultProps = {
  className: undefined,
  element: 'div',
  prefix: false,
  compact: false,
};


export default connect(({ session }) => ({ session }))(PatientNumber);
