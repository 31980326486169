import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { css } from 'emotion';
import styled from 'react-emotion';
import { numeric } from 'helpers/i18n';
import { errorHandlerBuilder } from 'helpers/ErrorHandler';
import {
  PageContent, contentStyle,
} from 'components/utils/Styles';
import { PAGE_CONTENT_WIDTH_V2_PX } from 'components/utils/PageContext';

import ScrollToTop from 'components/utils/ScrollToTop';
import MenuBar from 'components/menu/MenuBar';
import SiteFooter from 'components/branding/SiteFooter';
import QueryAware from 'components/home/QueryAware';
import Fragment from 'components/utils/Fragment';
import ZendeskChatIcon from 'components/utils/ZendeskChatIcon';

import LoginPage from 'components/login/LoginPage';

import Toast from 'components/utils/Toast';
import MiniGlobalContainer from 'containers/MiniGlobalContainer';
import withThemedComponents, { ThemeConfig } from 'components/utils/withThemedComponents';

import { mq } from 'components/utils/Responsive';

const v2LoginContainerStyle = css(mq({
  maxWidth: PAGE_CONTENT_WIDTH_V2_PX,
  width: ['100%', 'calc(100% - 50px)', 'calc(100% - 32px)'],
  backgroundColor: '#ffffff',
  paddingTop: '0px',
  paddingLeft: ['20px', '0px', '0px'],
  paddingRight: ['20px', '0px', '0px'],
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const createComponentStyles = ({ version }) => numeric(version, {
  other: {
    styles: {
      Container: React.Fragment,
      Content: PageContent,
      loginContentStyle: contentStyle,
      SiteFooterContainer: Fragment,
      ScrollTop: (
        <ScrollToTop>
          <MenuBar />
        </ScrollToTop>
      ),
    },
  },
  v2: {
    styles: {
      Container: styled.div`
        background-color: #ffffff;
        overflow-x: hidden;
      `,
      Content: PageContent,
      SiteFooterContainer: styled.div(),
      loginContentStyle: v2LoginContainerStyle,
      loginFooterContentStyle: v2LoginContainerStyle,
      ScrollTop: undefined,
    },
  },
});

const LoginContainer = ({ theme }) => {
  const {
    styles: {
      Container,
      Content,
      loginContentStyle,
      ScrollTop,
      SiteFooterContainer,
    },
  } = theme;
  /**
   * FIXME: We don't actually capture & display error messages on the login page.
   * This is why we never have full-screen errors, but also why these script and
   * reference errors continue to come in uncaught. Adding the error handling here,
   * but we'll want to probably address how we handle errors from a user-facing
   * perspective at some point.
   */
  useEffect(() => {
    errorHandlerBuilder.build({ onFatalError: () => {} });
  }, []);

  return (
    <Router>
      <Container>
        <Content className={loginContentStyle}>
          {ScrollTop}
          <QueryAware>
            <LoginPage />
          </QueryAware>
          <Toast />
          <MiniGlobalContainer hasError={false} />
        </Content>
        <ZendeskChatIcon />
        <SiteFooterContainer className={v2LoginContainerStyle}>
          <SiteFooter />
        </SiteFooterContainer>
      </Container>
    </Router>
  );
};

LoginContainer.propTypes = {
  theme: ThemeConfig.isRequired,
};

export default withThemedComponents(createComponentStyles)(LoginContainer);
