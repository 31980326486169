import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cx } from 'emotion';

import * as notificationActions from 'ducks/notifications';

import { trackEvent } from 'helpers/analytics';

import Spinner from 'components/utils/Spinner';
import { MEDIUM_LIGHT_GRAY_FILL } from 'components/utils/Colors';

import { MessageApi } from 'components/messages/withConversationActions';
import NotificationsListPopupPanel from 'components/notifications/NotificationsListPopupPanel';

import Notification from 'models/Notification';

import {
  Icon,
  InboxLinkDiv,
  inboxLinkContainerStyle,
  initializingStyle,
  spinnerContainerStyle,
  iconBorderStyle,
} from './MenuBarStyles';

const InAppNotifNotificationIcon = ({
  messageActions,
  conversationActions,
  markNotificationAsRead,
  notifications: {
    list: notifications,
    unread,
  },
  requestNotifications,
  resetNotificationsUnreadCount: resetUnreadCount,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      requestNotifications().finally(() => {
        setLoading(false);
      });
    }, 1000); // Delay to let other things happen first, i.e. dashboard
    return () => {
      setLoading(true);
    };
  }, []);
  const onClickTracking = () => {
    requestNotifications();
    trackEvent('Site Header', 'Click', 'Notifications Dropdown');
  };

  return (
    <NotificationsListPopupPanel
      notifications={notifications}
      requestNotifications={onClickTracking}
      messageActions={messageActions}
      conversationActions={conversationActions}
      resetUnreadCount={resetUnreadCount}
      markNotificationAsRead={markNotificationAsRead}
      trigger={(
        <div
          data-testid="in-app-notifications"
          css={cx(
            inboxLinkContainerStyle,
            loading ? initializingStyle : null,
          )}
        >
          <div className={iconBorderStyle}>
            <Icon className="mp-bell" color={MEDIUM_LIGHT_GRAY_FILL} />
          </div>
          {loading
            ? (
              <InboxLinkDiv css={cx(spinnerContainerStyle)}>
                <Spinner width="40px" small />
              </InboxLinkDiv>
            )
            : (
              <InboxLinkDiv>
                {unread < 100 ? unread : '99+'}
              </InboxLinkDiv>
            )}
        </div>
      )}
    />
  );
};
InAppNotifNotificationIcon.propTypes = {
  messageActions: MessageApi.isRequired,
  conversationActions: MessageApi.isRequired,
  markNotificationAsRead: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    list: PropTypes.arrayOf(Notification).isRequired,
    unread: PropTypes.number.isRequired,
  }).isRequired,
  requestNotifications: PropTypes.func.isRequired,
  resetNotificationsUnreadCount: PropTypes.func.isRequired,
};

export default connect(
  ({ notifications }) => ({ notifications }),
  notificationActions,
)(InAppNotifNotificationIcon);
