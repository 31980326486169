import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cx } from 'emotion';

import * as conversationActionDucks from 'ducks/conversations';
import * as messageActionDucks from 'ducks/messages';

import { DateTime } from 'helpers/dates';

import Session from 'models/Session';

import {
  Spinner,
  Colors,
} from 'components/utils';

import {
  Icon,
  InboxLinkDiv,
  initializingStyle,
  inboxLinkContainerStyle,
  inboxReadLinkStyle,
  spinnerContainerStyle,
  iconBorderStyle,
} from 'components/menu/MenuBarStyles';

import MessageListPopupPanel from 'components/messages/MessageListPopupPanel';

const {
  ERROR_LIGHT_FILL,
  MEDIUM_LIGHT_GRAY_FILL,
} = Colors;

const MessageNotificationIcon = ({
  session,
  messages,
  messageActions,
  hideUnreadIndicator,
}) => {
  const hasUnreadMessages = ({
    allowHideUnreadIndicator,
    last_read_at: lastReadAt,
    last_received_at: lastReceivedAt,
  } = {}) => {
    if (hideUnreadIndicator && allowHideUnreadIndicator) { return false; }
    if (!lastReadAt || !lastReceivedAt) { return false; }
    const readAt = DateTime.parse(lastReadAt);
    const minReadTime = DateTime.parse('2000-01-01 00:00'); // The min read time on server is 1990-01-01
    const receivedAt = DateTime.parse(lastReceivedAt);
    if (readAt.isBefore(minReadTime)) { return false; }

    return readAt.isBefore(receivedAt);
  };

  const createInbox = () => ({
    allowHideUnreadIndicator: false,
    inbox: messages,
    actions: {
      ...messageActions,
      openMessage: message => messageActions
        .requestMessageThread(message.message_content.referral.id, [
          {
            id: message.organization.id,
            name: message.organization.name,
            type: message.organization_type,
          },
          {
            id: message.message_content.organization.id,
            name: message.message_content.organization.name,
            type: message.message_content.organization_type,
          },
        ], message.message_content.referral),
    },
  });

  const { inbox, actions } = createInbox();
  const { initialized, list, unread } = inbox;
  const hasNewMessages = hasUnreadMessages(inbox);

  return (
    <MessageListPopupPanel
      session={session}
      messages={list}
      requestMessageList={actions.requestMessageList}
      requestMessageThreadById={actions.requestMessageThreadById}
      requestNewMessageThread={actions.requestNewMessageThread}
      sendMessage={actions.sendMessage}
      openMessage={actions.openMessage}
      trigger={(
        <div
          data-testid="messages"
          css={cx(
            inboxLinkContainerStyle,
            hasNewMessages ? inboxReadLinkStyle : null,
            !initialized ? initializingStyle : null,
          )}
        >
          <div className={iconBorderStyle}>
            <Icon color={hasNewMessages ? ERROR_LIGHT_FILL : MEDIUM_LIGHT_GRAY_FILL} unread={hasNewMessages} className="mp-mail" />
          </div>
          {!initialized
            ? (
              <InboxLinkDiv css={cx(spinnerContainerStyle)}>
                <Spinner width="40px" small />
              </InboxLinkDiv>
            )
            : (
              <InboxLinkDiv css={cx(hasNewMessages ? inboxReadLinkStyle : null)}>
                {unread}
              </InboxLinkDiv>
            )}
        </div>
      )}
    />
  );
};

const NotificationInboxApi = PropTypes.shape({
  list: PropTypes.arrayOf(PropTypes.shape({})),
  unread: PropTypes.number,
});

const NotificationActionsApi = PropTypes.shape({
  requestMessageList: PropTypes.func.isRequired,
  requestMessageUnreadCount: PropTypes.func.isRequired,
  requestMessageThreadById: PropTypes.func.isRequired,
  requestNewMessageThread: PropTypes.func,
  sendMessage: PropTypes.func,
});

MessageNotificationIcon.propTypes = {
  session: Session.isRequired,
  messages: NotificationInboxApi.isRequired,
  messageActions: NotificationActionsApi.isRequired,
  hideUnreadIndicator: PropTypes.bool,
};

MessageNotificationIcon.defaultProps = {
  hideUnreadIndicator: false,
};

const mapDispatchToProps = dispatch => ({
  conversationsActions: bindActionCreators(conversationActionDucks, dispatch),
  messageActions: bindActionCreators(messageActionDucks, dispatch),
});

export default connect(({
  session,
  messages,
  conversationsNotification,
}) => ({
  session,
  messages,
  conversationsNotification,
}), mapDispatchToProps)(MessageNotificationIcon);
