import React from 'react';
import { css } from 'emotion';

import { Purchases, getProviderAccessDetails } from 'models/Session';
import User from 'models/User';

const iconStyle = css`
  width: 100%;
  height: 100%;
  max-width:22px
`;

const IconChecked = () => (
  <svg data-testid="all_verified" className={iconStyle} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#DEE9FF" />
    <circle cx="11" cy="11" r="8" fill="#5D93FE" />
    <path
      d="M7.76777 9.46371L6 11.239L9.88909 15.1281L16.2531 8.76401L14.489 7L9.89659 11.5925L7.76777 9.46371Z"
      fill="white"
    />
  </svg>
);

const IconCheckedGreyed = () => (
  <svg data-testid="location_verified" className={iconStyle} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#F7F7F7" />
    <circle cx="11" cy="11" r="8" fill="#DBDBDB" />
    <path d="M7.76777 9.46371L6 11.239L9.88909 15.1281L16.2531 8.76401L14.489 7L9.89659 11.5925L7.76777 9.46371Z" fill="white" />
  </svg>
);

const IconPadLock = () => (
  <svg data-testid="none_verified" className={iconStyle} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2088 9.35286V6.02419C16.2088 3.51745 14.1145 1.5 11.5335 1.5H10.5246C7.94363 1.5 5.83839 3.51745 5.83839 6.02419V9.35286C4.80136 9.35554 4 10.1449 4 11.115V18.693C4 19.6646 4.84844 20.5 5.84886 20.5H16.2092C17.2096 20.5 18 19.6646 18 18.693V11.1149C18 10.1449 17.1987 9.3555 16.2088 9.35286ZM6.7812 6.02419C6.7812 4.02236 8.46352 2.41564 10.5246 2.41564H11.5336C13.5947 2.41564 15.2661 4.02236 15.2661 6.02419V9.37468H14.3233V6.02419C14.3233 4.52723 13.0749 3.33131 11.5336 3.33131H10.5246C8.98337 3.33131 7.72397 4.5272 7.72397 6.02419V9.37468H6.7812V6.02419V6.02419ZM13.3805 6.02419V9.37468H8.6667V6.02419C8.6667 5.03211 9.50318 4.24698 10.5246 4.24698H11.5335C12.555 4.24698 13.3805 5.03211 13.3805 6.02419ZM17.0573 18.693C17.0573 19.1726 16.6671 19.5844 16.1857 19.5844H5.8144C5.33302 19.5844 4.94281 19.1726 4.94281 18.693V11.136C4.94281 10.6563 5.33302 10.2904 5.8144 10.2904H16.1857C16.6671 10.2904 17.0573 10.6563 17.0573 11.136V18.693Z" fill="black" />
    <path d="M11.0043 12C10.0641 12 9.29004 12.7673 9.29004 13.7104C9.29004 14.2188 9.53052 14.6951 9.89702 15.0204V17.3482C9.89702 17.5506 10.0794 17.733 10.2819 17.733H11.7479C11.9503 17.733 12.0961 17.5505 12.0961 17.3482V15.0197C12.4993 14.698 12.7091 14.222 12.7091 13.7104C12.7091 12.7673 11.9494 12 11.0043 12ZM11.5381 14.5298C11.4346 14.5976 11.3631 14.7129 11.3631 14.8365V17H10.6301V14.8365C10.6301 14.7129 10.5769 14.5975 10.4734 14.5298C10.1914 14.3454 10.0277 14.039 10.0277 13.7104C10.0277 13.1715 10.466 12.733 11.0021 12.733C11.5429 12.733 11.9795 13.1715 11.9795 13.7104C11.9795 14.0441 11.8123 14.3504 11.5381 14.5298Z" fill="black" />
  </svg>
);

/**
 * An icon component used to display verified admin status
 */

const VerifiedAdminStatusIconComponent = (props) => {
  const { user, purchases } = props;
  const access = getProviderAccessDetails(user, purchases);
  if (access.hasClosedProviders) {
    if (access.isAdmin) {
      const isAdminForClosed = (user.roles || []).some(r => r === 'provider_admin');
      if (isAdminForClosed) {
        return <IconChecked />;
      }
    }
    return <IconCheckedGreyed />;
  }
  if (access.hasOpenProviders) {
    return <IconPadLock />;
  }
  return null;
};

VerifiedAdminStatusIconComponent.propTypes = {
  /** Logged in user object needs to passed as prop */
  user: User.isRequired,
  /** Purchases object needs to passed as prop */
  purchases: Purchases.isRequired,
};

export default VerifiedAdminStatusIconComponent;
