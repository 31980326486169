/* eslint-disable camelcase, no-nested-ternary, react/jsx-props-no-spreading */

import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { uniqBy } from 'lodash';
import { css, cx } from 'emotion';
import styled from 'react-emotion';
import qs from 'query-string';

import {
  FEATURE_PROVIDER_PROFILE_USE,
  FEATURE_PROVIDER_PROFILE_MVP,
  FEATURE_PROVIDER_PROFILE_PREVIEW,
} from 'ducks/session/types';

import ComingSoon, { COMING_SOON } from 'components/utils/ComingSoon';
import { applyTestIdentifier } from 'helpers/development';
import { STR } from 'translations/menus';
import { createTranslator } from 'helpers/i18n';
import Session, {
  isProviderAdmin,
  isHospitalAdmin,
  hasNoVerifiedIdentity,
  hasVerifiedIdentity,
  hasPendingVerifiedIdentity,
  isInboxInboundEnabled as allowInboxSubmenu,
  shouldDisableFeatureWithoutPurchase,
  shouldDisableFeatureWithoutFlagEnabled,
  shouldDisableInboxSubmenu,
} from 'models/Session';
import * as actions from 'ducks/ui';

import {
  Panel, Divider, Popup, Icon, Tag, Link, Colors, Styles,
} from 'components/utils';

import VerifiedAdminStatusIcon from 'components/admin/providers/VerifiedAdminStatusIcon';
import VerifiedAdminActions from 'components/admin/providers/VerifiedAdminActions';
import ProviderVerificationWarningIcon from 'components/menu/ProviderVerificationWarningIcon';
import { INTEGRATION_FEATURES } from 'components/integrations/AidinIntegrationsLayout';

const {
  LinkText, Secondary, PopupContent,
} = Styles;

const { NOTIFICATION_FILL, WHITE, WARNING_PROGRESS_FILL } = Colors;

export const getMenuText = (text) => STR(text, 'menu');
export const LOCATION_MENUBAR = 'menubar';
export const LOCATION_SIDEMENU = 'sidemenu';

const tr = createTranslator({
  account: getMenuText('account'),
  viewOrganization: getMenuText('viewOrganization'),
  previewOrganization: getMenuText('previewOrganization'),
  team: getMenuText('team'),
  locations: getMenuText('locations'),
  network: getMenuText('network'),
  inbox: getMenuText('inbox'),
  inboxInbound: getMenuText('inboxInbound'),
  inboxOutbound: getMenuText('inboxOutbound'),
  integrations: getMenuText('integrations'),
  logout: getMenuText('logout'),
  settings: getMenuText('settings'),
  purchases: getMenuText('purchases'),
  reports: getMenuText('reports'),
  loggedInAs: getMenuText('loggedInAs'),
  badgesCheckup: getMenuText('badgesCheckup'),
  verifyIdentity: getMenuText('verifyIdentity'),
});

const links = createTranslator({
  account: '/admin',
  viewOrganization: ({ user: { providers: [{ id }] } }) => `/providers/${id}`,
  previewOrganization: ({ user: { providers: [{ id }] } }) => `/preview/providers/${id}`,
  team: '/admin/team',
  locations: '/admin/locations',
  organization: ({ organization = {} }) => organization.url || '#',
  settings: '/admin/providers',
  purchases: '/admin/purchases',
  network: '/admin/locations',
  reports: '/reports',
  inbox: '/inbox',
  inboxInbound: '/inbox/inbound',
  inboxOutbound: '/inbox/outbound',
  integrations: '/integrations',
  becomeProviderAdmin: (session, { pathname, search } = {}) => `${pathname}?${qs.stringify({ ...qs.parse(search), mode: 'purchase', feature: 'verified_admin' })}`,
  verifyIdentity: '/',
});

const indentStyle = css`
  margin-left: 20px;
`;

const shouldDisableOrganizationLinks = (feature, session) => {
  const {
    features = {},
  } = session;

  const published = features[FEATURE_PROVIDER_PROFILE_USE]
    || features[FEATURE_PROVIDER_PROFILE_MVP];

  if (feature === FEATURE_PROVIDER_PROFILE_USE && published) {
    return false;
  }
  if (feature === FEATURE_PROVIDER_PROFILE_PREVIEW && features[FEATURE_PROVIDER_PROFILE_PREVIEW]) {
    return published; // disable preview mode when feature has been published
  }
  return true;
};

const Items = {
  All: [
    { key: 'account', disabled: false },
  ],
  Hospital: [
    { key: 'team', allowed: isHospitalAdmin, disabled: false },
    { key: 'locations', disabled: false },
    { key: 'network', roles: ['hospital_admin'], disabled: false },
    {
      key: 'reports', roles: ['reporting'], disabled: false, omitLocation: LOCATION_SIDEMENU,
    },
    {
      key: 'inbox', disabled: session => shouldDisableFeatureWithoutPurchase(session, 'aidin_inbox'),
    },
    {
      key: 'inboxOutbound', allowed: allowInboxSubmenu, disabled: shouldDisableInboxSubmenu, parent: 'inbox', className: indentStyle,
    },
    {
      key: 'inboxInbound', allowed: allowInboxSubmenu, disabled: shouldDisableInboxSubmenu, parent: 'inbox', className: indentStyle,
    },
    {
      key: 'integrations', disabled: session => shouldDisableFeatureWithoutFlagEnabled(session, ...INTEGRATION_FEATURES),
    },
  ],
  Provider: [
    {
      key: 'viewOrganization', disabled: (session) => shouldDisableOrganizationLinks(FEATURE_PROVIDER_PROFILE_USE, session),
    },
    {
      key: 'previewOrganization', disabled: (session) => shouldDisableOrganizationLinks(FEATURE_PROVIDER_PROFILE_PREVIEW, session),
    },
    { key: 'team', showVerifiedAdminStatusIcon: true, disabled: false },
    { key: 'settings', disabled: false },
    { key: 'locations', disabled: false },
    {
      key: 'reports', showVerifiedAdminStatusIcon: true, disabled: false, omitLocation: LOCATION_SIDEMENU,
    },
    {
      key: 'inbox', disabled: session => shouldDisableFeatureWithoutPurchase(session, 'aidin_inbox'),
    },
    {
      key: 'integrations', disabled: session => shouldDisableFeatureWithoutFlagEnabled(session, ...INTEGRATION_FEATURES),
    },
    {
      key: 'purchases', showVerifiedAdminStatusIcon: true, disabled: false,
    },
  ],
};

const MenuLink = styled(LinkText.withComponent('div'))`
  padding: 5px 0px;
`;

const menuLinkPanelStyle = css`
  text-decoration: none;
`;

export const getSettingsMenuItems = ({ session, showDisabled = false, renderLocation = LOCATION_SIDEMENU }) => {
  const {
    user: {
      organizations = [],
    },
  } = session;

  // FIXME: Should check against user.abilities.can('manage', 'Provider', { id: id })
  const seen = {};
  const options = organizations.reduce((sum, organization) => {
    const { organization_type } = organization;
    if (seen[organization_type]) {
      return sum;
    }
    seen[organization_type] = true;
    return (
      sum.concat((Items[organization_type] || [])
        .filter(({ disabled }) => (typeof disabled === 'function'
          ? disabled(session) === showDisabled
          : disabled === showDisabled))
        .filter(({ allowed }) => !allowed || allowed(session.user, session))))
      .filter(({ omitLocation }) => omitLocation !== renderLocation);
  }, showDisabled ? [] : Items.All);

  return uniqBy(options, 'key')
    .map(item => ({
      label: tr(item.key),
      url: links(item.key, session),
      ...item,
    }));
};

export const isMenuItemVisible = (session, renderLocation, menuItem) => getSettingsMenuItems({ session, renderLocation }).some(({ key }) => key === menuItem);

export const getSettingsMenuSecondaryLinks = (session, location, actionHandler = () => { }) => [
  {
    key: 'becomeProviderAdmin',
    disabled: hasPendingVerifiedIdentity(session.user),
    organization_type: 'Provider',
  },
  {
    key: 'verifyIdentity',
    disabled: hasNoVerifiedIdentity(session.user) || hasVerifiedIdentity(session.user),
    onClick: () => actionHandler('openIdentityVerificationModal'),
    icon: { name: 'warning', color: WARNING_PROGRESS_FILL },
    organization_type: 'Provider',
  },
].filter(item => !item.disabled).map(item => ({
  ...item,
  label: tr(item.key),
  url: links(item.key, session, location),
})).filter(({ organization_type: type }) => (
  !type || session.organizations.some(org => org.organization_type === type)
));

const renderComingSoonSection = (session) => {
  const items = getSettingsMenuItems({ session, showDisabled: true }).map(({ label }) => (
    <Secondary key={label}>{label}</Secondary>
  ));
  if (items.length === 0) {
    return null;
  }
  return (
    <>
      <Divider />
      <ComingSoon asLabel orientation="vertical" align="left" spacing={10}>
        <div>{items}</div>
      </ComingSoon>
    </>
  );
};

const renderSecondarySection = (session, location, actionHandler, close) => {
  const secondaryLinks = getSettingsMenuSecondaryLinks(session, location, actionHandler);
  if (secondaryLinks.length === 0) { return <Divider />; }
  return (
    <Panel spacing={0}>
      {secondaryLinks.map(({
        key, label, url, target, onClick, icon,
      }) => (
        <React.Fragment key={key}>
          <Panel orientation="horizontal" align="center" spacing={4}>
            {key === 'becomeProviderAdmin' ? <VerifiedAdminActions url={url} session={session} onClick={close} target={target} />
              : (onClick ? <MenuLink onClick={onClick}>{label}</MenuLink>
                : (
                  <Link key={key} to={url} onClick={close} target={target}>
                    <MenuLink>{label}</MenuLink>
                  </Link>
                ))}
            {icon && <Icon onClick={onClick} {...icon} size={20} />}
          </Panel>
          <>
            {key !== 'becomeProviderAdmin' && <Divider />}
          </>
        </React.Fragment>
      ))}
    </Panel>
  );
};

const openBadgesCheckupModal = (session, requestBadgesCheckupModal) => () => {
  const { user, organizations } = session;
  const ids = organizations.filter(({ organization_type: type }) => type === 'Provider').map(({ id }) => id);
  if (ids.length > 0) {
    requestBadgesCheckupModal(ids, user);
  }
};

const createActionHandler = oracle => action => {
  switch (action) {
    case 'openIdentityVerificationModal':
      oracle.openIdentityVerificationModal();
      break;
    default:
      break;
  }

  oracle.close();
};

const SettingsMenu = ({
  onLogout,
  location,
  session,
  trigger,
  openIdentityVerificationModal,
  requestBadgesCheckupModal: checkup,
}) => (
  <Popup
    autoWidth
    trigger={trigger}
    position="bottom right"
    offsetY={10}
    disableScrolling
  >
    {({ close }) => (
      <PopupContent>
        <Panel spacing={10}>
          <Panel spacing={0}>
            {getSettingsMenuItems({ session }).map(({
              key, label, tag, url, showVerifiedAdminStatusIcon, className,
            }) => (
              <Link
                key={key}
                to={url}
                onClick={close}
                className={cx(menuLinkPanelStyle, className)}
              >
                <Panel align="center" spacing={4} orientation="horizontal">
                  <MenuLink {...applyTestIdentifier(`setting-menu-${key}`)}>{label}</MenuLink>
                  {tag && <Tag color={NOTIFICATION_FILL} textColor={WHITE}>{tag}</Tag>}
                  {showVerifiedAdminStatusIcon && <VerifiedAdminStatusIcon />}
                  {(key === 'settings') && <ProviderVerificationWarningIcon margin="0 5px" showCheckOnVerified />}
                </Panel>
              </Link>
            ))}
            {!COMING_SOON && isProviderAdmin(session.user) && (
              <MenuLink onClick={openBadgesCheckupModal(session, checkup)}>{tr('badgesCheckup')}</MenuLink>
            )}
            <MenuLink {...applyTestIdentifier('setting-menu-logout')} onClick={() => { close(); onLogout(); }}>{tr('logout')}</MenuLink>
            {renderComingSoonSection(session)}
          </Panel>
          {renderSecondarySection(session, location, createActionHandler({
            openIdentityVerificationModal, close,
          }), close)}
          <div>
            <Secondary>{tr('loggedInAs')}:</Secondary>
            <Secondary>{session.user.name}</Secondary>
            {!session.user.previewer && session.user.name !== session.user.email && (
              <Secondary>{session.user.email}</Secondary>
            )}
          </div>
        </Panel>
      </PopupContent>
    )}
  </Popup>
);
SettingsMenu.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  onLogout: PropTypes.func.isRequired,
  openIdentityVerificationModal: PropTypes.func.isRequired,
  requestBadgesCheckupModal: PropTypes.func.isRequired,
  session: Session.isRequired,
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export { shouldDisableFeatureWithoutPurchase, shouldDisableFeatureWithoutFlagEnabled };
export default withRouter(connect(null, actions)(SettingsMenu));
